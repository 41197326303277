import { TABLE_NAMES } from "app/constants/table-names";
import { STORAGE } from "app/contexts/user-context";
import { uuidv4 } from "app/helpers";
import { IBuyerRecord } from "app/types/buyer";
import { IOrder, IOrderFields, Project, Sa, Transaction, Unit } from "app/types/order";
import { PoaFileResponse, PoaFormPayload, PoaFormResponse } from "app/types/poaForm";
import { IUser } from "app/types/user";
import axios from "axios";
import { StorageReference, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import dayjs from "dayjs";
import Airtable from 'airtable';

const baseUrl = process.env.REACT_APP_AIRTABLE_API_BASE;
const token = process.env.REACT_APP_AIRTABLE_API_TOKEN;
const dbId = `${process.env.REACT_APP_AIRTABLE_BASE_ID}`;
const csBaseId = `${process.env.REACT_APP_AIRTABLE_CS_BASE_ID}`;

Airtable.configure({ apiKey: token });

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const airTableClient = {
  listBase: axios.get(`${baseUrl}/meta/bases`, config),
  getBaseSchema: axios.get(`${baseUrl}/meta/bases/${dbId}/tables`, config),
  listRecords: async (tableName: string, params: any = {}) => {
    const res = await axios.request({
      method: "GET",
      url: `${baseUrl}/${dbId}/${tableName}`,
      ...config,
      params,
    });
    return res.data;
  },
  getBuyer: (user: IUser) => axios.get(`${baseUrl}/${dbId}/${TABLE_NAMES.Buyers}/${user.buyerId}`, config),
  getOrdersByIds: async (orderIds: string[]) => {
    return new Promise<IOrder[]>((resolve) => {
      const filterByFormula = `FIND(RECORD_ID(), "${orderIds.join(",")}")`;
      Airtable.base(`${dbId}`).table(TABLE_NAMES.Orders).select({
        filterByFormula
      }).all( async (err, records) => {
        if(!records){
          return
        }
        const orderData: IOrder[] = [];
        for await (const record of records){
          const base = Airtable.base(dbId);
          const csBase = Airtable.base(csBaseId);
          const fields = record.fields as unknown as IOrderFields;
          const project = await base.table(TABLE_NAMES.LocalProjects).find(`${fields.Project}`) as unknown as Project;
          const unit = await base.table(TABLE_NAMES.LocalInventory).find(String(fields.Unit[0])) as unknown as Unit;
          const sa = await base.table(TABLE_NAMES.SpecialArrangements).select({
            filterByFormula: `FIND(RECORD_ID(), "${(fields["🚩 Special Arrangements 2"] ?? []).join(",")}")`
          }).all() as unknown as Sa[]
          const transactions = await base.table(TABLE_NAMES.Transactions).select({
            filterByFormula: `FIND(RECORD_ID(), "${(fields.Transactions ?? []).join(",")}")`
          }).all() as unknown as Transaction[];
          const poa = await csBase.table(TABLE_NAMES.POA).select({
            filterByFormula: `
            AND(
              SEARCH("${unit.fields.ID}", {Unit No}),
              {Project Name} = "${project?.fields["Project Name"]}"
            )
            `
          }).all() as unknown as PoaFileResponse[];
  
          orderData.push({
            id: record.id,
            fields,
            project,
            unit,
            sa,
            transactions,
            poa
          } as IOrder);
        }
        resolve(orderData);
      });
    })
  },
  getOrderById: async (orderId: string, buyerRecord: IBuyerRecord | undefined) => {
    const orderRes = await axios.get(`${baseUrl}/${dbId}/${TABLE_NAMES.Orders}/${orderId}`, config);
    const orderData = orderRes.data;

    if (!orderData) {
      return null
    }

    let mixedData = { ...orderData } as IOrder;

    const projectId = (orderData.fields.Project as string[] ?? [])[0] ?? null;
    if (!projectId) {
      return mixedData as IOrder;
    }

    const projectRes = await axios.get(`${baseUrl}/${dbId}/${TABLE_NAMES.LocalProjects}/${projectId}`, config);
    mixedData = {
      ...orderData,
      project: projectRes.data,
    };

    const unitId = (orderData.fields.Unit as string[])?.[0] ?? null;
    if (unitId) {
      const unitRes = await axios.get(`${baseUrl}/${dbId}/${TABLE_NAMES.LocalInventory}/${unitId}`, config);
      mixedData = {
        ...mixedData,
        unit: unitRes.data,
      };
    }

    const txnIds = (orderData.fields.Transactions as string[]) ?? [] as string[];
    if (txnIds && Array.isArray(txnIds)) {
      const txnPromises: Promise<any>[] = txnIds.map(id => {
        return axios.get(`${baseUrl}/${dbId}/${TABLE_NAMES.Transactions}/${id}`, config);
      })
      const txnRes = await Promise.allSettled(txnPromises);
      const fulfilled = txnRes.filter((txn): txn is PromiseFulfilledResult<any> => txn.status === 'fulfilled');
      mixedData = {
        ...mixedData,
        transactions: fulfilled.map(txn => (txn.value as any).data)
      }
    }

    const saIds1 = (orderData.fields?.['🚩 Special Arrangements 2'] as string[]) ?? [] as string[];
    const saIds2 = (orderData.fields?.['Special Arrangements'] as string[]) ?? [] as string[];
    const saIds = [...saIds1, ...saIds2];
    if (saIds && Array.isArray(saIds)) {
      const saPromises: Promise<any>[] = saIds.map((id) => {
        return axios.get(`${baseUrl}/${dbId}/${TABLE_NAMES.SpecialArrangements}/${id}`, config);
      });
      const saRes = await Promise.allSettled(saPromises);
      const fulfilledSaRes = saRes.filter((sa): sa is PromiseFulfilledResult<any> => sa.status === 'fulfilled');
      mixedData = {
        ...mixedData,
        sa: fulfilledSaRes.map(sa => (sa.value as any).data)
      }
    }

    // get poa record
    const queryData = mixedData as IOrder;
    const poaRes = await axios.request({
      method: 'GET',
      url: `${baseUrl}/${csBaseId}/${TABLE_NAMES.POA}`,
      headers: config.headers,
      params: {
        // FIND({Unit No}, "${queryData.fields["Order ID"]}"),
        // {Unit No} = "${queryData.unit.fields.ID ?? ''}",
        filterByFormula:`
        AND(
          SEARCH("${queryData.unit.fields.ID}", {Unit No}),
          {Project Name} = "${queryData.project?.fields["Project Name"]}"
        )
        `
      }
    });
    // console.log(poaRes.data?.records);
    mixedData = {
      ...mixedData,
      poa: (poaRes.data?.records ?? []) as PoaFileResponse[]
    }

    return mixedData as IOrder;
  },
  getRecord: async (table: TABLE_NAMES, id: string) => {
    return await axios.get(`${baseUrl}/${dbId}/${table}/${id}`, config);
  },
  createOrUpdatePoa: async (payload: PoaFormPayload, existingResponse: PoaFormResponse | undefined = undefined) => {
    try {
      // take out all the file first
      const {
        // destructure all files
        "Upload Buyer's Passport": uploadBuyersPassportFile,
        "Arrival Stamp Photo": arrivalStampPhoto,
        "Upload Marriage Certificate": UploadMarriageCertificate,
        "Upload Spouse Passport": UploadSpousePassport,
        // need to handle the date
        "Arrival Stamp Date": arrivalStampDate,
        "Upload 2nd Buyer's Passport copy": upload2ndBuyersPassport,
        "Upload 3rd Buyer's Passport copy": upload3rdBuyersPassport,
        ...stringFields
      } = payload;

      // edit this to add or remove file fields to process.
      const uploadNVP = [
        {
          key: "Upload Buyer's Passport",
          files: uploadBuyersPassportFile
        }
      ]

      // check file if undefined or push to nvp
      const checkAndPushFileNVP = (key:string, files: File[] | undefined) => {
        if(!files){
          return
        }
        uploadNVP.push({
          key,
          files
        })
      };
      checkAndPushFileNVP("Arrival Stamp Photo", arrivalStampPhoto)
      checkAndPushFileNVP("Upload Marriage Certificate", UploadMarriageCertificate)
      checkAndPushFileNVP( "Upload Spouse Passport", UploadSpousePassport)
      checkAndPushFileNVP( "Upload 2nd Buyer's Passport copy", upload2ndBuyersPassport)
      checkAndPushFileNVP( "Upload 3rd Buyer's Passport copy", upload3rdBuyersPassport)

      interface UploadItems {
        key: string;
        files: UploadItemsFiles[];
      }
      interface UploadItemsFiles {
        rawFile: File;
        uid: string;
        filename: string;
        storageRef: StorageReference;
      }

      const uploadItems: UploadItems[] = uploadNVP.map(item => {
        const { key, files } = item;
        return ({
          key,
          files: files.map(file => {
            const uid = uuidv4();
            const filename = file.name;
            const storageRef = ref(STORAGE, `poa/${uid}`)
            return {
              rawFile: file,
              uid,
              filename,
              storageRef,
            } as UploadItemsFiles
          })
        })
      });

      const allUploadPromises = uploadItems.flatMap(item => item.files.map(file => uploadBytes(file.storageRef, file.rawFile)));

      await Promise.all(allUploadPromises);

      const allFileUrlPromises = uploadItems.flatMap(item =>
        item.files.map(file => getDownloadURL(file.storageRef))
      );

      const allUrls = await Promise.all(allFileUrlPromises);

      const readyFilePayloads = uploadItems.map(item => {
        const {key, files} = item;
        return {
          key,
          value: files.map(file => {
            const {uid, filename} = file;
            const url = allUrls.find(u => u.includes(uid));
            if(!url){
              return null
            }
            return {
              url, filename
            }
          }).filter(v => v !== null) as {
            url: string, filename: string
          }[]
        }
      })

      type UploadFieldsValue = ({url: string, filename: string} | PoaFileResponse)[];
      interface UploadFields { [key: string]: UploadFieldsValue }

      const uploadFields: UploadFields = {};

      readyFilePayloads.forEach((item) => {
        console.group('check field:', item.key);
        const existingItems = (existingResponse?.fields[item.key as keyof PoaFormPayload] ?? []) as UploadFieldsValue;
        console.log(`existingItems`, existingItems);
        const mergedItems: UploadFieldsValue = [...item.value, ...existingItems];
        console.log(`mergedItems`, mergedItems)
        console.groupEnd();
        uploadFields[item.key] = mergedItems;
      });

      const url = `${baseUrl}/${csBaseId}/${TABLE_NAMES.POA}/${existingResponse?.id ?? ''}`;
      const { headers } = config;

      const finalFields = {
        ...stringFields,
        ...uploadFields
      }

      if(arrivalStampDate){
        Object.assign(finalFields, {
          "Arrival Stamp Date": dayjs(arrivalStampDate).format('YYYY-MM-DD')
        })
      }

      const records = [{ fields: finalFields }];

      console.log(`records before create`, records);

      await axios.request({
        method: existingResponse ? 'PATCH' : 'POST',
        url,
        headers,
        data: existingResponse ? {
          fields: {...finalFields}
        } : {records}
      })
      return { message: `success` };
    } catch (error) {
      return { message: 'error' };
    }
  }
};

// AND(
          
//   FIND("${queryData.project.fields["Project Name"]}", {Project Name}),
// )