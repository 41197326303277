import { IonButton, IonContent, IonHeader, IonIcon, IonLabel, IonModal, IonToolbar } from "@ionic/react";
import { useGlobalContext } from "app/contexts/global-context";
import { useUserContext } from "app/contexts/user-context";
import { BREAKPOINTS } from "app/helpers";
import { IPushNotification } from "app/types/pushNotification";
import { AuthLayout } from "components/AuthLayout";
import { Content } from "components/Content";
import { LoadingModule } from "components/Global/LoadingModule";
import { SideBar } from "components/SideBar";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import parse from "html-react-parser";
import { chevronBack } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsFillCheckCircleFill, BsPersonCircle } from "react-icons/bs";
import { twMerge } from "tailwind-merge";
import { useWindowSize } from "usehooks-ts";
import './style.css';

export function NotificationPage() {
  const { width } = useWindowSize();
  const isMd = width >= BREAKPOINTS.md;

  const { user } = useUserContext();
  const { pushes:notifications } = useGlobalContext();
  // const [notifications, setNotifications] = useState<IPushNotification[] | undefined>(undefined);
  const [selectedNotification, setSelectedNotification] = useState<IPushNotification | undefined>(undefined);

  useEffect(() => {
    if (!user || !notifications) {
      return;
    }

    if (isMd && notifications.length) {
      setSelectedNotification(notifications[0]);
    }
  }, [user, isMd, notifications]);

  useEffect(() => console.log(`notifications`, notifications), [notifications]);

  return (
    <AuthLayout>
      <SideBar sideMenu={isMd}>{notifications ? <NotificationList notifications={notifications} selected={selectedNotification} setSelected={setSelectedNotification} /> : <LoadingModule />}</SideBar>
      <Content className="p-0">
        {isMd ? (
          selectedNotification ? (
            <NotificationDetails notification={selectedNotification} />
          ) : (
            <div className="w-full mt-8 py-12 flex justify-center items-center p-4 bg-slate-100 rounded-md" >No Notification Selected</div>
          )
        ) : notifications ? (
          <NotificationList notifications={notifications} selected={selectedNotification} setSelected={setSelectedNotification} />
        ) : (
          <LoadingModule />
        )}
      </Content>
    </AuthLayout>
  );
}

interface NotificationListProps {
  notifications: IPushNotification[];
  selected: IPushNotification | undefined;
  setSelected: React.Dispatch<React.SetStateAction<IPushNotification | undefined>>;
}
const NotificationList: React.FC<NotificationListProps> = ({ notifications, selected, setSelected }: NotificationListProps) => {
  const { width } = useWindowSize();
  const isMd = width >= BREAKPOINTS.md;
  const {t:tran} = useTranslation();

  return (
    <>
      <ul className="w-full flex flex-col divide-y px-2">
        {
          !notifications.length ?
          <div className="p-4 bg-slate-100 rounded-md" >
            <h4>{tran('You dont have any notifications')}</h4>
          </div> :
        notifications.map((notification) => (
          <li
            key={notification.id}
            className={twMerge(`flex w-full items-start cursor-pointer transition hover:bg-slate-200`, selected && selected.id === notification.id ? "bg-slate-200" : "")}
            onClick={() => {
              setSelected(notification);
            }}
          >
            <img alt="" src="assets/img/notify.svg" className="w-12 h-12 md:w-8 md:h-8 p-2 md:p-1.5 rounded-full bg-slate-300 m-4 md:m-2 mr-0" />
            <div className="p-4 md:p-2 grow flex flex-col">
              <div className="flex items-center justify-between">
                <h4 className="font-semibold text-primary">{notification.sender}</h4>
                <small className="text-slate-400">{dayjs((notification.date as unknown as Timestamp).toDate()).format("DD/MM/YYYY")}</small>
              </div>
              <small className="uppercase font-semibold text-slate-600">{notification.title}</small>
              <small>{parse(notification.subject)}</small>
            </div>
          </li>
        ))}
      </ul>
      <IonModal isOpen={!!selected && !isMd} onDidDismiss={() => setSelected(undefined)}>
        <IonHeader>
          <IonToolbar>
            <IonButton size='small' fill='clear' color='light' onClick={() => setSelected(undefined)} >
              <IonIcon slot="start" icon={chevronBack} />
              <IonLabel>Back</IonLabel>
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>{selected ? <NotificationDetails notification={selected} /> : null}</IonContent>
      </IonModal>
    </>
  );
};

interface NotificationDetailsProps {
  notification: IPushNotification;
}
const NotificationDetails: React.FC<NotificationDetailsProps> = ({ notification }: NotificationDetailsProps) => {
  const { user } = useUserContext();
  console.log(notification.date)
  return (
    <div className="">
      {/* <img alt="" src={faker.image.urlPicsumPhotos()} className="w-full h-[30vh] object-cover" /> */}

      <div className="flex flex-col bg-slate-200 p-8 gap-8 min-h-[65vh] md:min-h-fit">
        {/* sender header */}
        <div className="flex w-full flex-col md:flex-row">
          {/* sender card */}
          <div className="order-2 md:order-1 flex grow">
            <BsPersonCircle className="w-6 h-6 mr-2" />
            <div>
              <p className="flex gap-1 font-semibold text-primary items-center">
                <BsFillCheckCircleFill className="w-3 h-3 text-primary" />
                <span>{notification.sender}</span>
              </p>
              <div className="flex gap-3 text-sm text-slate-400">
                {`To: `}
                <div className="flex gap-1 items-center">
                  <BsFillCheckCircleFill className="w-3 h-3 text-primary" />
                  <span>{user?.displayName}</span>
                </div>
              </div>
            </div>
          </div>

          {/* date of message */}
          <div className="order-1 md:order-2 text-slate-500 mb-2">{dayjs((notification.date as unknown as Timestamp).toDate()).format(`dddd, DD MMM YYYY [at] h:mmA`)}</div>
        </div>

        {/* content area */}
        <div>{parse(notification.content)}</div>
      </div>
    </div>
  );
};
