import { PropertiesFilter, PropertyType, propertyTypes } from "app/types/property";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCheckSquare, BsCheckSquareFill, BsSearch } from "react-icons/bs";
import { twMerge } from "tailwind-merge";
import { useDebounce } from 'usehooks-ts';


interface Props {
    filter: PropertiesFilter;
    setFilter: React.Dispatch<React.SetStateAction<PropertiesFilter>>;
}

export default function PropertyListFilterModule({
    filter, setFilter
}: Props) {
    const [value, setValue] = useState('');
    const debouncedValue = useDebounce<string>(value, 500);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }

    useEffect(() => {
        setFilter(f => ({ ...f, keyword: debouncedValue }));
    }, [debouncedValue, setFilter]);

    const { t: tran } = useTranslation();

    return (
        <aside className="p-4 flex flex-col gap-4">
            {/* search bar */}
            <div className="mb-12 md:hidden" ></div>
            <div className="hidden md:block">
                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">{tran(`Search`)}</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <BsSearch className="text-primary" />
                    </div>
                    <input
                        type="search"
                        id="default-search"
                        className={twMerge(
                            `block w-full p-2 pl-10 text-sm text-gray-900`,
                            `border border-gray-300 rounded-full bg-gray-50`,
                            `focus:ring-primary focus:border-primary`,
                        )}
                        placeholder={tran("Quick Search")}
                        value={value}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div>
                <h4
                    className="font-bold text-primary text-md"
                >{tran(`Property Filters`)}</h4>
                {
                    propertyTypes.map(ty => (
                        <CheckBoxItem key={ty.typeName} ty={ty} filter={filter} setFilter={setFilter}/>
                    ))
                }
            </div>
        </aside>
    )
}

interface CheckBoxItemProps{
    ty: PropertyType
    filter: PropertiesFilter,
    setFilter: Dispatch<SetStateAction<PropertiesFilter>>
}
function CheckBoxItem({
    ty, filter, setFilter
}: CheckBoxItemProps) {
    const { t: tran } = useTranslation();
    const [checked, setChecked] = useState(filter.types.map(t => t.value).includes(ty.value))

    return (
        <div key={`type-option-${ty.typeName}`}
            className="w-full py-2 flex gap-2 justify-start items-center cursor-pointer"
            onClick={() => {
                setChecked(c => !c);
                setFilter(f => ({
                    ...f,
                    types: f.types.includes(ty) ?
                        [...f.types].filter(tt => tt !== ty) : [...f.types].concat([ty])
                }))
            }}
        >
            <div>
                <div className={twMerge(
                    checked ? `` : `hidden`
                )}>
                    <BsCheckSquareFill
                        className="text-primary"
                    />
                </div>
                <div className={twMerge(
                    !checked ? `` : `hidden`
                )}>
                    <BsCheckSquare
                        className="text-slate-300"
                    />
                </div>
            </div>
            <span
                className="mr-auto"
            >{tran(ty.typeName)}</span>
            <div
                className={twMerge(
                    `p-1 rounded-full w-10 h-1 ${ty.color}`
                )}
            />
        </div>
    )
}