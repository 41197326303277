import axios from "axios";
import { IBuyerRecord } from "./types/buyer";
import { getFunctions, httpsCallable } from "firebase/functions";

export { v4 as uuidv4 } from "uuid";
export * as yup from "yup";

declare global {
  interface Window {
    tidioChatApi: { [key: string]: any }; // or whatever type
    $respond: { [key: string]: any }; // or whatever type
    __respond_settings: { [key: string]: any };
    $zoho: { [key: string]: any };
  }
}

export const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536
}

export const respondIOMessage = async (
  buyerRecord: IBuyerRecord, 
  service: string,
  option: string,
  locale: string
) => {
  window.$respond.do("chat:open");
  const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_RESPOND_IO_TOKEN}`
  }

  /** workflow trial */
  const contactUrl = `https://api.respond.io/v2/contact/create_or_update/email:${buyerRecord.fields["Email Address"]}`;
  const contactPayload = {
    firstName: buyerRecord.fields["First Name"],
    lastName: buyerRecord.fields["Last Name"],
    email: buyerRecord.fields["Email Address"],
  }
  await axios.request({
    method: 'POST',
    url: contactUrl,
    headers,
    data: contactPayload
  });

  const functions = getFunctions();
  const sendMessage = httpsCallable(functions, 'triggerRespondIOMessage');
  await sendMessage({ 
    email: buyerRecord.fields["Email Address"],
    service, option, locale
  });

  return
  /** END OF workflow trial */
}

export const zohoNewServiceChat = async (buyerRecord: IBuyerRecord, service = 'Furniture Moving Service $299.99') => {
  console.log(`zohoNewServiceChat`)
  const message = `Hi, I would like to make an enquiry for the below service: ${service}.\n 
    Buyer name: ${buyerRecord.fields["Buyer Name"]}
    Email: ${buyerRecord.fields["Email Address"]}
    `;
  window.$zoho.salesiq.visitor.question(message);
  window.$zoho.salesiq.chat.start();
}

export const sentResetPwLink = async (email: string) => {
  const functions = getFunctions();
  const sendFunction = httpsCallable(functions, 'sentResetPasswordLink');
  await sendFunction({ 
    email
  });

  return
}