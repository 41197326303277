import { Listbox, Transition } from '@headlessui/react';
import { IonLoading, useIonViewWillLeave } from "@ionic/react";
import { useUserContext } from "app/contexts/user-context";
import { IUser } from "app/types/user";
import { CenterContainer } from "components/Global/CenterContainer";
import LoadingScreen from 'components/Global/LoadingScreen';
import MultiSelectLayout from 'components/MultiSelectLayout';
import { Fragment, useCallback, useState } from "react";
import { useTranslation } from 'react-i18next';
import { BsCheck, BsChevronDown } from "react-icons/bs";


export default function UserGateway() {
    const { logout, userDocs, setSingleAuthUser } = useUserContext();
    const [selected, setSelected] = useState<IUser | null>(userDocs?.filter(dd => dd.role !== 'admin')[0] ?? null)
    const [loading, setLoading] = useState(false);
    const { t: tran } = useTranslation()

    const handleSelect = useCallback(async () => {
        if (!selected)
            return
        setLoading(true)
        await setSingleAuthUser(selected);
        setLoading(false);
    }, [selected, setSingleAuthUser])

    useIonViewWillLeave(() => setLoading(false), []);

    if (!userDocs) {
        return <LoadingScreen />
    }

    return (
        <MultiSelectLayout>
            <CenterContainer>
                <h1 className="text-2xl font-medium text-primary" >{tran(`User Gateway`)}</h1>
                {/* Select users */}
                <div className="p-8 bg-slate-100 rounded-xl max-w-lg flex flex-col gap-4" >
                    <p>{tran(`Please select the user you would like to login as:`)}</p>
                    <Listbox value={selected} onChange={setSelected}>
                        <div className="relative mt-1">
                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className="block truncate">{selected?.displayName}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <BsChevronDown
                                        className="h-4 w-4 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {userDocs?.filter(dd => dd.role !== 'admin').map((person, personIdx) => (
                                        <Listbox.Option
                                            key={personIdx}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                                }`
                                            }
                                            value={person}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                            }`}
                                                    >
                                                        {person.displayName}
                                                    </span>
                                                    {selected ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                            <BsCheck className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                    <button
                        className="p-3 bg-primary text-white hover:bg-teal-600 transition disabled:bg-slate-400"
                        onClick={handleSelect}
                        disabled={!selected}
                    >{tran(`Select User`)}</button>
                </div>

                {/* or logout */}
                <button
                    onClick={logout}
                    className="p-2 rounded-md bg-slate-200 text-slate-500"
                >{tran(`Logout`)}</button>
            </CenterContainer>
            <IonLoading isOpen={loading} />
        </MultiSelectLayout>
    )
}