import { DB } from "app/contexts/user-context";
import { IUserMenu } from "app/types/myAccounts";
import { Content } from "components/Content";
import { LoadingModule } from "components/Global/LoadingModule";
import { doc, getDoc } from "firebase/firestore";
import parse from 'html-react-parser';
import { useEffect, useState } from "react";

const getUserMenu = async () => {
  const res = await getDoc(doc(DB, `user-menu/user-menu`));
  if(!res.exists()){
    return null
  }
  return {id: res.id, ...res.data()} as IUserMenu;
}

function UserMenuView() {
  const [content, setContent] = useState<IUserMenu | undefined | null>(undefined);

  useEffect(() => {
    const loadContent = async () => {
      const data = await getUserMenu();
      setContent(data);
    }
    loadContent()
  }, [])

  return (
    <Content>
      <div>
        {
          !content ? <LoadingModule/> :
          <>
            <img src={content.cover.src} alt={content.cover.alt} 
              className="w-full h-[30vh] object-cover"
            />
            <div className="bg-slate-100 text-slate-800 p-8 flex flex-col gap-4 rich-content" >
              {parse(content.section)}
            </div>
          </>
        }
      </div>
    </Content>
  );
}

export default UserMenuView;
