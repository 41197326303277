export enum PoaFormBeenThailand {
    YES = "Yes 是",
    NO = "No 否"
}

export enum PoaFormMaritalStatus {
    Single = "Single 单身",
    Married = "Married 已婚",
    Widowed = "Widowed 丧偶",
    Divorced = "Divorced 离婚"
}

export enum PoaNumbersOfBuyersOption {
    Single = "Single Buyer 一位",
    Multiple = "More than one buyer 多于一位"
}

export enum PoaYesNo {
    Yes = "Yes",
    No = "No"
}

export interface PoaFormPayload {
    "Unit No": string;
    "Project Name": string;
    "Have you been to Thailand within last 6 months": PoaFormBeenThailand;
    "Marital Status": PoaFormMaritalStatus;
    "Numbers of Buyers": PoaNumbersOfBuyersOption;
    "If Spouse is second buyer?": PoaYesNo;
    "Buyer's First Name in English": string;
    "Buyer's Last Name in English": string;
    "Upload Buyer's Passport": File[];
    "Current Address(Must be same as SPA)": string;
    "Buyer's Father_ First Name in English": string;
    "Buyer's Father_ Last Name in English": string;
    "Buyer's Mother_First Name in English": string;
    "Buyer's Mother_Last Name in English": string;

    // if been to Thailand past 6 months
    "Arrival Stamp Date"?: Date;
    "Arrival Stamp Photo"?: File[];

    // if is married
    "Buyer's Spouse_First Name in English"?: string;
    "Buyer's Spouse_Last Name in English"?: string;
    "Upload Marriage Certificate"?: File[];
    "Upload Spouse Passport"?: File[];

    // If Spouse is second buyer
    "Spouse(Co-buyer)'s Father_First Name in Engllish"?: string;
    "Spouse(Co-buyer)'s Father_Last Name in English"?: string;
    "Spouse(Co-buyer)'s Mother_First Name in English"?: string;
    "Spouse(Co-buyer)'s Mother_Last Name in English"?: string;

    // If more than one buyers
    // 2nd buyer
    "2nd Buyer's First Name In English"?: string;
    "2nd Buyer's Last Name In English"?: string;
    "2nd Buyer's Father_First Name in English"?: string;
    "2nd Buyer's Father_Last Name in English"?: string;
    "2nd Buyer's Mother-First Name in English"?: string;
    "2nd Buyer's Mother_Last Name in English"?: string;
    "Upload 2nd Buyer's Passport copy"?: File[];
    // 3rd buyer
    "3rd Buyer's First Name In Englsih"?: string;
    "3rd Buyer's Last Name in English"?: string;
    "3rd Buyer's Father_First Name in English"?: string;
    "3rd Buyer's Father_Last Name in English"?: string;
    "3rd Buyer's Mother_First Name in English"?: string;
    "3rd Buyer's Mother_Last Name in English"?: string;
    "Upload 3rd Buyer's Passport copy"?: File[];
}

export const defaultPoaFormPayload: PoaFormPayload = {
    "Unit No": '',
    'Project Name': '',
    'Have you been to Thailand within last 6 months': PoaFormBeenThailand.NO,
    'Marital Status': PoaFormMaritalStatus.Single,
    'Numbers of Buyers': PoaNumbersOfBuyersOption.Single,
    'If Spouse is second buyer?': PoaYesNo.No,
    "Buyer's First Name in English": '',
    "Buyer's Last Name in English": '',
    "Upload Buyer's Passport": [],
    "Current Address(Must be same as SPA)": '',
    "Buyer's Father_ First Name in English": '',
    "Buyer's Father_ Last Name in English": '',
    "Buyer's Mother_First Name in English": '',
    "Buyer's Mother_Last Name in English": '',
}

export interface PoaFileResponse{
    url: string;
    filename: string;
    id: string;
}
export interface PoaFormResponse {
    "id": string,
    fields: {
        "Unit No": string;
        "Project Name": string;
        "Have you been to Thailand within last 6 months": PoaFormBeenThailand;
        "Marital Status": PoaFormMaritalStatus;
        "Numbers of Buyers": PoaNumbersOfBuyersOption;
        "If Spouse is second buyer?": PoaYesNo;
        "Buyer's First Name in English": string;
        "Buyer's Last Name in English": string;
        "Upload Buyer's Passport": PoaFileResponse[];
        "Current Address(Must be same as SPA)": string;
        "Buyer's Father_ First Name in English": string;
        "Buyer's Father_ Last Name in English": string;
        "Buyer's Mother_First Name in English": string;
        "Buyer's Mother_Last Name in English": string;

        // if been to Thailand past 6 months
        "Arrival Stamp Date"?: string;
        "Arrival Stamp Photo"?: PoaFileResponse[];

        // if is married
        "Buyer's Spouse_First Name in English"?: string;
        "Buyer's Spouse_Last Name in English"?: string;
        "Upload Marriage Certificate"?: PoaFileResponse[];
        "Upload Spouse Passport"?: PoaFileResponse[];

        // If Spouse is second buyer
        "Spouse(Co-buyer)'s Father_First Name in Engllish"?: string;
        "Spouse(Co-buyer)'s Father_Last Name in English"?: string;
        "Spouse(Co-buyer)'s Mother_First Name in English"?: string;
        "Spouse(Co-buyer)'s Mother_Last Name in English"?: string;

        // If more than one buyers
        // 2nd buyer
        "2nd Buyer's First Name In English"?: string;
        "2nd Buyer's Last Name In English"?: string;
        "2nd Buyer's Father_First Name in English"?: string;
        "2nd Buyer's Father_Last Name in English"?: string;
        "2nd Buyer's Mother-First Name in English"?: string;
        "2nd Buyer's Mother_Last Name in English"?: string;
        "Upload 2nd Buyer's Passport copy"?: PoaFileResponse[];
        // 3rd buyer
        "3rd Buyer's First Name In Englsih"?: string;
        "3rd Buyer's Last Name in English"?: string;
        "3rd Buyer's Father_First Name in English"?: string;
        "3rd Buyer's Father_Last Name in English"?: string;
        "3rd Buyer's Mother_First Name in English"?: string;
        "3rd Buyer's Mother_Last Name in English"?: string;
        "Upload 3rd Buyer's Passport copy"?: PoaFileResponse[];
    }
}