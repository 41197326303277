import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonModal, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillLeave } from "@ionic/react";
import { airTableClient } from "app/Client";
import { useGlobalContext } from "app/contexts/global-context";
import { useUserContext } from "app/contexts/user-context";
import { IOrder, Sa } from "app/types/order";
import { AuthLayout } from "components/AuthLayout";
import { Content } from "components/Content";
import { LoadingModule } from "components/Global/LoadingModule";
import { SideBar } from "components/SideBar";
import { POAFormModal } from "components/properties/POAFormModal";
import { PropertyStatusSection } from "components/properties/PropertyStatusSection";
import dayjs from "dayjs";
import { closeOutline } from "ionicons/icons";
import React, { Fragment, useCallback, useState } from "react";
import ga from "react-ga4";
import { useTranslation } from "react-i18next";
import { BsCheckCircle, BsUpload } from "react-icons/bs";
import { useParams } from "react-router";
import { twMerge } from "tailwind-merge";

const PropertyDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [order, setOrder] = useState<IOrder | undefined>(undefined);
  const { setLoading } = useGlobalContext();
  const { buyerRecord, user } = useUserContext();
  const [showBModal, setShowBModal] = useState(false);
  const [showSPAModal, setShowSPAModal] = useState(false);
  const [showTXModal, setShowTXModal] = useState(false);
  const [showPoaForm, setShowPoaForm] = useState(false);
  const { t: tran } = useTranslation();

  const loadOrder = useCallback(async () => {
    if (!buyerRecord) { return }
    setLoading(true);
    const res = await airTableClient.getOrderById(id, buyerRecord);
    console.log('Order record', res);
    if (res) {
      setOrder(res);
    }
    setLoading(false);
  }, [setLoading, setOrder, id, buyerRecord]);

  useIonViewDidEnter(() => {
    if (!id) {
      return;
    }
    loadOrder();
  }, [id, loadOrder])

  useIonViewDidEnter(() => {
    if (!buyerRecord)
      return
    console.log('buyer record', buyerRecord)
  }, [buyerRecord])

  useIonViewWillLeave(() => {
    setOrder(undefined)
  })

  return (
    <AuthLayout>
      <SideBar></SideBar>
      <Content className="p-0" >
        {!order ? <LoadingModule /> : (
          <section className="flex flex-col w-full gap-4 md:py-8">
            <PropertyStatusSection order={order} />

            <div className="p-4 md:p-0 flex flex-col gap-8">
              {/* unit details */}
              <div className="w-full border-slate-300 rounded-xl overflow-hidden">
                <p className={twMerge(`gradient p-3 text-white font-semibold bg-gradient-to-r`)}>{tran(`Unit Details`)}</p>
                <div className="p-3 grid grid-cols-1 md:grid-cols-3 md:gap-4">
                  <div>
                    <DetailsField label={tran("Unit No.")} value={order.unit.fields.ID || "N/A"} />
                    <DetailsField label={tran("Unit Type")} value={order.unit.fields["Unit Type"] || "N/A"} />
                    <DetailsField label={tran("Floor")} value={order.unit.fields["Floor"] || "N/A"} />
                    <DetailsField label={tran("Beds")} value={order.unit.fields["Beds"] || "N/A"} />
                  </div>
                  <div>
                    <DetailsField label={tran("Baths")} value={order.unit.fields["Baths"] || "N/A"} />
                    <DetailsField label={tran("Sinking Fund")} 
                      value={
                        order.unit.fields["Sinking Fund (from Project)"] ?
                        tran("sinkingFundValue", {value: order.unit.fields["Sinking Fund (from Project)"].join(', ') })
                        : "N/A"
                      } 
                    />
                    <DetailsField label={tran("Common Area Fee")}
                      value={
                        order.unit.fields["Maintenance Fee (from Project)"] ?
                        tran("maintenanceFeeValue", {value: order.unit.fields["Maintenance Fee (from Project)"]?.join(', ') })
                        : "N/A"
                      }
                    />
                    <DetailsField label={tran("Unit Size")} 
                      value={
                        order.unit.fields["Title Deed Area (Sq.m)"] ?
                        `${order.unit.fields["Title Deed Area (Sq.m)"] } ${tran("sq.m.")}`
                        : "N/A"
                      }
                    />
                  </div>
                  <div>
                    <DetailsField label={tran("Unit Price")} value={tran("thbValue", {value: Number(order.fields["Transacted Price (Adjusted)"] || 0).toLocaleString()})} />
                    <DetailsField
                      label={tran("Others")}
                      value={
                        (order.sa ?? [] as Sa[]).filter(item => {
                          const isDisplay = item.fields.Display === "Display"
                          // const haveContent = item.fields["[General] Category"] && item.fields["[Rebate] Rebate Title"]
                          return isDisplay
                        }).length ? 
                        order.sa.filter(item => {
                          const isDisplay = item.fields.Display === "Display"
                          // const haveContent = item.fields["[General] Category"] && item.fields["[Rebate] Rebate Title"]
                          return isDisplay
                        })
                        .map(s => `${s.fields['[General] Category']}${s.fields["[Rebate] Rebate Title"] ? ` - ${s.fields["[Rebate] Rebate Title"]}` : ``}`)
                        .join('\n') 
                        : 'N/A'
                      }
                    />
                  </div>
                </div>
                {/* action buttons */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-2">
                  <button
                    className={twMerge(
                      `flex ring-2 ring-primary text-primary py-0 px-2 rounded-md gap-2 items-center relative justify-center`,
                      `transition text-center no-underline`,
                      `hover:bg-primary hover:text-white`,
                      `h-12`
                    )}
                    onClick={() => {
                      setShowPoaForm(true)
                      ga.event({
                        category: `${order.unit.fields.ID}`, 
                        action: 'Show POA Details Form',
                        label: `${user?.displayName}`
                      })
                    }}
                  >
                    <div className="flex flex-col">
                      <b>{(order.poa?.length || 0) > 0 ? tran(`Edit`) : tran(`Submit`)}{` `}{tran(`POA Details Form`)}</b>
                      <small>{tran(`(Power of Attorney of title transfer)`)}</small>
                    </div>
                    {
                      (order.poa?.length || 0) > 0 ? <BsCheckCircle className="absolute right-2" /> : <BsUpload className="absolute right-2" />
                    }
                  </button>

                  <a className={twMerge(
                    `flex ring-2 ring-primary text-primary py-0 px-2 rounded-md gap-2 items-center relative justify-center`,
                    `hover:bg-primary hover:text-white transition no-underline`,
                    `h-12`
                  )}
                    href="https://airtable.com/apptWTEerSNako2wh/shrIWlwIJpUmsxO1q"
                    target='_blank'
                    rel="noreferrer"
                    onClick={() => {
                      ga.event({
                        category: `${order.unit.fields.ID}`, 
                        action: 'Upload Payment Receipts Button Clicked',
                        label: `${user?.displayName}`
                      })
                    }}
                  >
                    <div className="flex flex-col">
                      <b>{tran(`Upload Payment Receipts`)}</b>
                    </div>
                    <BsUpload className="absolute right-2" />
                  </a>
                </div>
              </div>

              {/* doc and payment */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="">
                  <p className={twMerge(`gradient p-3 text-white font-semibold bg-gradient-to-r rounded-t-xl`)}>{tran(`Documents`)}</p>
                  <div className="p-3 grid grid-cols-1">
                    <DetailsField
                      label={tran("Reservation Forms")}
                      button={
                        <button className="rounded-full gradient bg-gradient-to-r text-white py-1 px-3 text-sm font-semibold"
                          onClick={() => setShowBModal(true)}
                        >{tran(`View`)}</button>
                      }
                    />
                    <DetailsField
                      label={tran("SPA & RA")}
                      button={
                        <button className="rounded-full gradient bg-gradient-to-r text-white py-1 px-3 text-sm font-semibold"
                          onClick={() => setShowSPAModal(true)}
                        >{tran(`View`)}</button>
                      }
                    />
                    <DetailsField
                      label={tran("Payment Records")}
                      button={<button className="rounded-full gradient bg-gradient-to-r text-white py-1 px-3 text-sm font-semibold"
                        onClick={() => setShowTXModal(true)}
                      >{tran(`View`)}</button>}
                    />
                  </div>
                </div>

                <div className="">
                  <p className={twMerge(`gradient p-3 text-white font-semibold bg-gradient-to-r rounded-t-xl`)}>{`${tran(`Payment Schedule`)} *`}</p>
                  <div className="p-3 grid grid-cols-1">
                    <DetailsField label={tran("First Deposit")} value={order.fields["1st Payment Due date"] ? dayjs(order.fields["1st Payment Due date"]).format('D/M/YYYY') : 'N/A'} />
                    <DetailsField label={tran("Second Deposit")} value={order.fields["2nd Payment Due date"] ? dayjs(order.fields["2nd Payment Due date"]).format('D/M/YYYY') : 'N/A'} />
                    <DetailsField label={tran("Third Deposit")} value={order.fields["3rd Payment Due date"] ? dayjs(order.fields["3rd Payment Due date"]).format('D/M/YYYY') : 'N/A'} />
                    <DetailsField label={tran("Final Payment")} value={order.fields["Last Payment due date"] ? dayjs(order.fields["Last Payment due date"]).format('D/M/YYYY') : 'N/A'} />

                    {/* remarks */}
                    <div className="mt-8">
                      <small>
                        * {tran(`Please note that the payment schedule provided is for reference only and is based on the date of the booking form.`)}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
        )}

        {/* <PoaModal open={showPoa} setOpen={setShowPoa} /> */}
        <BookingFormsModal
          order={order}
          open={showBModal}
          setOpen={setShowBModal}
        />
        <SPAFilesModal
          order={order}
          open={showSPAModal}
          setOpen={setShowSPAModal}
        />
        <TXFilesModal
          order={order}
          open={showTXModal}
          setOpen={setShowTXModal}
        />
        {/* POA Form Modal */}
        <POAFormModal
          isOpen={showPoaForm}
          setOpen={setShowPoaForm}
          order={order}
        />
      </Content>
    </AuthLayout>
  );
};

export default PropertyDetails;

// fragmenting atoms

interface DetailsFieldProps {
  label: string;
  value?: string | number;
  button?: React.ReactNode;
  className?: string;
}

const DetailsField: React.FC<DetailsFieldProps> = ({ label, value, button }: DetailsFieldProps) => (
  <div className="w-full flex gap-2 items-start justify-between border-b border-slate-300 py-2">
    <small className="text-primary font-semibold">{label}</small>
    {value ?
      <small className="whitespace-break-spaces" >{value}</small> : null
    }
    {
      button ?? null
    }
  </div>
);

interface BookingFormsModalProps {
  open: boolean;
  setOpen: (b: boolean) => void;
  order: IOrder | undefined;
}
function BookingFormsModal({
  open, setOpen, order
}: BookingFormsModalProps) {
  const { t: tran } = useTranslation();

  return (
    <IonModal
      isOpen={open}
      onDidDismiss={() => setOpen(false)}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle color={'light'}>{tran(`Reservation Forms`)}</IonTitle>
          <IonButton color={'light'} slot="end" fill='clear'
            onClick={() => setOpen(false)}
          >
            <IonIcon icon={closeOutline} />
            <IonLabel>{tran(`Back`)}</IonLabel>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {order?.fields["Booking Form"]?.map(f => (
            <IonItem key={f.id}>
              <IonLabel>{f.filename}</IonLabel>
              <IonButton color='primary' size='small' href={f.url} target='_blank' >
                {/* <IonIcon icon={downloadOutline} slot="start" /> */}
                <IonLabel>{tran(`Download`)}</IonLabel>
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  )
}

function SPAFilesModal({
  open, setOpen, order
}: BookingFormsModalProps) {
  const { t: tran } = useTranslation()

  return (
    <IonModal
      isOpen={open}
      onDidDismiss={() => setOpen(false)}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle color={'light'}>{tran(`SPA & SA`)}</IonTitle>
          <IonButton color={'light'} slot="end" fill='clear'
            onClick={() => setOpen(false)}
          >
            <IonIcon icon={closeOutline} />
            <IonLabel>{tran(`Back`)}</IonLabel>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {order?.fields["SPA File"]?.map(f => (
            <IonItem key={f.id}>
              <IonLabel>{f.filename}</IonLabel>
              <IonButton color='primary' size='small' href={f.url} target='_blank' >
                {/* <IonIcon icon={downloadOutline} slot="start" /> */}
                <IonLabel>{tran(`Download`)}</IonLabel>
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  )
}

function TXFilesModal({
  open, setOpen, order
}: BookingFormsModalProps) {
  const { t: tran } = useTranslation()
  return (
    <IonModal
      isOpen={open}
      onDidDismiss={() => setOpen(false)}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle color={'light'}>{tran(`Payment Records`)}</IonTitle>
          <IonButton color={'light'} slot="end" fill='clear'
            onClick={() => setOpen(false)}
          >
            <IonIcon icon={closeOutline} />
            <IonLabel>{tran(`Back`)}</IonLabel>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {order?.transactions && order?.transactions?.map(t => (
            <Fragment key={t?.id}>
              <IonItemDivider>
                <IonLabel>
                  <b className="text-bold" >{dayjs(t?.fields["Payment Date"])?.format(`DD MMM YYYY`)}</b>
                </IonLabel>
              </IonItemDivider>
              {
                t?.fields?.Attachments?.map(att => (
                  <IonItem key={`${t.id}-${att.id}`}>
                    <IonLabel>
                      {att.filename}
                    </IonLabel>
                    <IonButton color='primary' size='small' href={att.url} target='_blank' >
                      <IonLabel>{tran(`Download`)}</IonLabel>
                    </IonButton>
                  </IonItem>
                ))
              }

            </Fragment>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  )
}