import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}

const Container: React.FC<PropsWithChildren<Props>> = ({ children, className }: PropsWithChildren<Props>) => {
  return <div className={twMerge(`flex flex-col w-full max-w-7xl m-auto px-4`, className ?? ``)}>{children}</div>;
};

export default Container;
