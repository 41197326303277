import { ILocaleData } from "app/types/global";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";


export default function useContentLocales() {
    const {i18n} = useTranslation();
    // const isDefault = i18n.language === 'en';

    const renderLocalizedData = useCallback(<T>(
        data: T,
        localData: ILocaleData<T>[],
      ) => {
        const localizedData = localData.find(d => d.locale === i18n.language);
        if(!localizedData){
          return data;
        }
        const mergedData: T = {
          ...data,
          ...localizedData?.data
        }
        // console.log('localised data:', mergedData);
        return mergedData;
      }, [i18n]);

    return {
        renderLocalizedData,
    }
}
