import { Icon } from '@iconify/react';
import { IonAlert, IonButton, IonIcon, IonLabel, IonLoading } from "@ionic/react";
import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { airTableClient } from 'app/Client';
import { useUserContext } from 'app/contexts/user-context';
import { IOrder } from "app/types/order";
import { PoaFileResponse, PoaFormBeenThailand, PoaFormMaritalStatus, PoaFormPayload, PoaFormResponse, PoaNumbersOfBuyersOption, PoaYesNo, defaultPoaFormPayload } from "app/types/poaForm";
import dayjs, { Dayjs } from 'dayjs';
import { FormikErrors, useFormik } from 'formik';
import { closeOutline } from "ionicons/icons";
import snakeCase from 'lodash/snakeCase';
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';

interface Props {
    // for modal
    isOpen: boolean;
    setOpen: (b: boolean) => void;
    // for prefill
    order: IOrder | undefined;
}

const arrayOfFilesSchema = yup.array();
// const arrayOfFilesRequiredSchema = arrayOfFilesSchema.min(1, "Please upload at least 1 file.");

const conditionalRequire = (schema: yup.AnySchema, key: string, condition: any) => {
    return schema.when(key, {
        is: (field: any) => field === condition,
        then: (schema) => schema.required()
    })
}

// set validation schema
const POASchema = yup.object().shape({
    "Unit No": yup.string().required("This field is required."),
    "Project Name": yup.string().required("This field is required."),
    "Have you been to Thailand within last 6 months": yup.string().required("This field is required."),
    "Marital Status": yup.string().required("This field is required."),
    "Numbers of Buyers": yup.string().required("This field is required."),
    "If Spouse is second buyer?": yup.string().required("This field is required."),
    "Buyer's First Name in English": yup.string().required("This field is required."),
    "Buyer's Last Name in English": yup.string().required("This field is required."),
    "Upload Buyer's Passport": arrayOfFilesSchema,
    "Current Address(Must be same as SPA)": yup.string().required("This field is required."),
    "Buyer's Father_ First Name in English": yup.string().required("This field is required."),
    "Buyer's Father_ Last Name in English": yup.string().required("This field is required."),
    "Buyer's Mother_First Name in English": yup.string().required("This field is required."),
    "Buyer's Mother_Last Name in English": yup.string().required("This field is required."),
    // if been to Thailand past 6 months
    "Arrival Stamp Date": conditionalRequire(
        yup.date(),
        "Have you been to Thailand within last 6 months",
        PoaFormBeenThailand.YES
    ),
    "Arrival Stamp Photo": arrayOfFilesSchema,
    // if is married
    "Buyer's Spouse_First Name in English": conditionalRequire(
        yup.string(),
        "Marital Status", PoaFormMaritalStatus.Married
    ),
    "Buyer's Spouse_Last Name in English": conditionalRequire(
        yup.string(),
        "Marital Status", PoaFormMaritalStatus.Married
    ),
    "Upload Marriage Certificate": arrayOfFilesSchema,
    "Upload Spouse Passport": arrayOfFilesSchema,
    // If Spouse is second buyer
    "Spouse(Co-buyer)'s Father_First Name in Engllish": conditionalRequire(
        yup.string(),
        "If Spouse is second buyer?", PoaYesNo.Yes
    ),
    "Spouse(Co-buyer)'s Father_Last Name in English": conditionalRequire(
        yup.string(),
        "If Spouse is second buyer?", PoaYesNo.Yes
    ),
    "Spouse(Co-buyer)'s Mother_First Name in English": conditionalRequire(
        yup.string(),
        "If Spouse is second buyer?", PoaYesNo.Yes
    ),
    "Spouse(Co-buyer)'s Mother_Last Name in English": conditionalRequire(
        yup.string(),
        "If Spouse is second buyer?", PoaYesNo.Yes
    ),
    // If more than one buyers
    "2nd Buyer's First Name In English": yup.string(),
    "2nd Buyer's Last Name In English": yup.string(),
    "2nd Buyer's Father_First Name in English": yup.string(),
    "2nd Buyer's Father_Last Name in English": yup.string(),
    "2nd Buyer's Mother-First Name in English": yup.string(),
    "2nd Buyer's Mother_Last Name in English": yup.string(),
    "3rd Buyer's First Name In Englsih": yup.string(),
    "3rd Buyer's Last Name in English": yup.string(),
    "3rd Buyer's Father_First Name in English": yup.string(),
    "3rd Buyer's Father_Last Name in English": yup.string(),
    "3rd Buyer's Mother_First Name in English": yup.string(),
    "3rd Buyer's Mother_Last Name in English": yup.string(),
})

export function POAFormModal({
    isOpen, setOpen, order
}: Props) {

    const { buyerRecord } = useUserContext()
    const [submitted, setSubmitted] = useState(false);
    const {t:tran} = useTranslation()

    // close and reset
    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const existingFullResponse: PoaFormResponse | undefined = order?.poa?.[0];

    interface UnwantedFields {
        Name: string;
        Mapping: string;
        "Last Modified": string;
        "Created": string;
    }

    const existingFormResponse: Partial<PoaFormPayload> & Partial<UnwantedFields> = useMemo(() =>
        (order?.poa?.[0]?.fields ?? {}),
        [order]);

    // console.log('existingForm', existingFormResponse);

    const {
        "Arrival Stamp Date": existingArrivalStampDate,
        "Arrival Stamp Photo": f1,
        "Upload Buyer's Passport": f2,
        "Upload Marriage Certificate": f3,
        "Upload Spouse Passport": f4,
        "Upload 2nd Buyer's Passport copy": f5,
        "Upload 3rd Buyer's Passport copy": f6,
        Name,
        Mapping,
        Created,
        "Last Modified": existLastModified,
        ...existFields
    } = useMemo(() => existingFormResponse, [existingFormResponse]);


    const initialValues: PoaFormPayload = useMemo(() => ({
        ...defaultPoaFormPayload,
        "Unit No": existingFormResponse?.['Unit No'] ?? order?.unit.fields.ID ?? '',
        "Project Name": existingFormResponse?.['Project Name'] ?? order?.project.fields["Project Name"] ?? '',
        "Arrival Stamp Date": existingArrivalStampDate ?
            dayjs(existingArrivalStampDate).toDate() : undefined,
        "Buyer's First Name in English": buyerRecord?.fields['First Name'] ?? '',
        "Buyer's Last Name in English": buyerRecord?.fields['Last Name'] ?? '',
        ...existFields,
    }), [order, buyerRecord, existingFormResponse, existFields, existingArrivalStampDate])

    const handleSubmit = useCallback(async (data: PoaFormPayload) => {
        console.log(`Payload before send:`, data);
        setOpen(false)
        await airTableClient.createOrUpdatePoa(data, existingFullResponse);
        setSubmitted(true);
    }, [setSubmitted, setOpen, existingFullResponse])

    const { values, handleChange, setFieldValue, errors, dirty, isValid, isSubmitting, handleSubmit: formikHandleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
        validationSchema: POASchema,
        validateOnChange: true
    })

    // useEffect(() => console.log(values), [values]);
    const theme = useTheme();

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={onClose}
                fullWidth
                fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
                maxWidth='lg'
            >
                <DialogTitle>
                    <div
                        className='flex justify-between items-center'
                    >
                        <h2 color={'light'} >{tran(`Details for POA Form`)}</h2>
                        <IonButton slot="end" fill="clear" color='primary'
                            onClick={onClose}
                        >
                            <IonIcon icon={closeOutline} />
                            <IonLabel>{tran(`Cancel`)}</IonLabel>
                        </IonButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <form name='poa-form' id='poa-form' onSubmit={formikHandleSubmit} >
                        <div className="p-0 md:p-4 flex flex-col gap-8">
                            {/* Title and description */}

                            {/* Property info */}
                            <FormSection
                                title="Property Details 物业详情"
                            >
                                <FormField
                                    fieldType="text"
                                    name="Unit No"
                                    label="Unit No. 单位号码"
                                    value={values["Unit No"]}
                                    onChange={handleChange}
                                    error={errors['Unit No']}
                                />
                                <FormField
                                    fieldType="text"
                                    name="Project Name"
                                    label="Project Name 项目名称"
                                    value={values["Project Name"]}
                                    onChange={handleChange}
                                    error={errors["Project Name"]}
                                />
                                <FormField
                                    fieldType='radio'
                                    name="Have you been to Thailand within last 6 months"
                                    label="Have you been to Thailand within last 6 months"
                                    sublabel="你在过去6个月曾否到入境泰国"
                                    value={values["Have you been to Thailand within last 6 months"]}
                                    onChange={handleChange}
                                    options={Object.keys(PoaFormBeenThailand).map(k => ({
                                        name: PoaFormBeenThailand[k as keyof typeof PoaFormBeenThailand],
                                        value: PoaFormBeenThailand[k as keyof typeof PoaFormBeenThailand],
                                    }))}
                                    error={errors["Have you been to Thailand within last 6 months"]}
                                />
                                {values['Have you been to Thailand within last 6 months'] === PoaFormBeenThailand.YES ?
                                    <FormSection
                                        title='Thailand Arrival Details'
                                        subSection
                                    >
                                        <FormField
                                            fieldType='date'
                                            name='Arrival Stamp Date'
                                            label="Arrival Stamp Date within 6 months"
                                            sublabel='请填写入境章日期'
                                            value={values['Arrival Stamp Date']}
                                            onChange={(v) => setFieldValue("Arrival Stamp Date", v)}
                                            error={errors["Arrival Stamp Date"]}
                                        />
                                        <FormField
                                            fieldType='upload'
                                            name="Arrival Stamp Photo"
                                            label="Upload Arrival Stamp Photo"
                                            sublabel='上传入境章图片'
                                            value={values["Arrival Stamp Photo"]}
                                            onChange={(files) => {
                                                setFieldValue("Arrival Stamp Photo", files)
                                            }}
                                            error={errors["Arrival Stamp Photo"]}
                                            existingResponse={existingFullResponse}
                                        />
                                    </FormSection>
                                    : null
                                }
                                <FormField
                                    fieldType='radio'
                                    name="Numbers of Buyers"
                                    label="Numbers of Buyers 买家数目"
                                    sublabel={`If more than one buyers, all other buyers have to submit information respectively.\n每位买家都需要提供父母名字`}
                                    value={values['Numbers of Buyers']}
                                    onChange={handleChange}
                                    options={Object.keys(PoaNumbersOfBuyersOption).map(k => ({
                                        name: PoaNumbersOfBuyersOption[k as keyof typeof PoaNumbersOfBuyersOption],
                                        value: PoaNumbersOfBuyersOption[k as keyof typeof PoaNumbersOfBuyersOption],
                                    }))}
                                    error={errors["Numbers of Buyers"]}
                                />
                            </FormSection>

                            {/* Primary Buyer info */}
                            <FormSection
                                title={`Buyer's Details 买家详细信息`}
                            >
                                <FormField
                                    fieldType="text"
                                    name="Buyer's First Name in English"
                                    label="Buyer's First Name in English 买家英文名字"
                                    value={values["Buyer's First Name in English"]}
                                    onChange={handleChange}
                                    error={errors["Buyer's First Name in English"]}
                                />
                                <FormField
                                    fieldType="text"
                                    name="Buyer's Last Name in English"
                                    label="Buyer's Last Name in English 买家英文姓氏"
                                    value={values["Buyer's Last Name in English"]}
                                    onChange={handleChange}
                                    error={errors["Buyer's Last Name in English"]}
                                />

                                {/* upload passport */}
                                <FormField
                                    fieldType='upload'
                                    name="Upload Buyer's Passport"
                                    label="Upload Buyer's Passport 上传买家护照"
                                    value={values["Upload Buyer's Passport"]}
                                    onChange={(files) => {
                                        setFieldValue("Upload Buyer's Passport", files)
                                    }}
                                    error={errors["Upload Buyer's Passport"]}
                                />
                                <FormField
                                    fieldType='textarea'
                                    name="Current Address(Must be same as SPA)"
                                    label='Current Address in English (Must be same as SPA)'
                                    sublabel='买家的地址（必须与买卖合同内的地址一致）'
                                    value={values['Current Address(Must be same as SPA)']}
                                    onChange={handleChange}
                                    error={errors["Current Address(Must be same as SPA)"]}
                                />
                                <FormField
                                    fieldType='radio'
                                    name="Marital Status"
                                    label="Marital Status"
                                    value={values['Marital Status']}
                                    onChange={handleChange}
                                    options={Object.keys(PoaFormMaritalStatus).map(k => ({
                                        name: PoaFormMaritalStatus[k as keyof typeof PoaFormMaritalStatus],
                                        value: PoaFormMaritalStatus[k as keyof typeof PoaFormMaritalStatus],
                                    }))}
                                    error={errors["Marital Status"]}
                                />
                            </FormSection>

                            {/* Spouse Info */}
                            {values['Marital Status'] === PoaFormMaritalStatus.Married ?
                                <>
                                    <FormSection
                                        title='Spouse Info 配偶信息'
                                    >
                                        <FormField
                                            fieldType='text'
                                            name="Buyer's Spouse_First Name in English"
                                            label="Buyer's Spouse First Name in English"
                                            value={values['Buyer\'s Spouse_First Name in English']}
                                            onChange={handleChange}
                                            error={errors["Buyer's Spouse_First Name in English"]}
                                        />
                                        <FormField
                                            fieldType='text'
                                            name="Buyer's Spouse_Last Name in English"
                                            label="Buyer's Spouse Last Name in English"
                                            value={values["Buyer's Spouse_Last Name in English"]}
                                            onChange={handleChange}
                                            error={errors["Buyer's Spouse_Last Name in English"]}
                                        />
                                        <FormField
                                            fieldType='upload'
                                            name="Upload Spouse Passport"
                                            label="Upload Spouse Passport 上传买家配偶护照"
                                            value={values["Upload Spouse Passport"]}
                                            onChange={(files) => {
                                                setFieldValue("Upload Spouse Passport", files)
                                            }}
                                            error={errors['Upload Spouse Passport']}
                                        />
                                        <FormField
                                            fieldType='upload'
                                            name="Upload Marriage Certificate"
                                            label="Upload Marriage Certificate 上传结婚证书图片"
                                            value={values["Upload Marriage Certificate"]}
                                            onChange={(files) => {
                                                setFieldValue("Upload Marriage Certificate", files)
                                            }}
                                            error={errors['Upload Marriage Certificate']}
                                        />
                                        <FormField
                                            fieldType='radio'
                                            name="If Spouse is second buyer?"
                                            label="If Spouse is second buyer? 配偶是否联名买家？"
                                            sublabel={`If Yes, Spouse needs to provide father and mother's name\n如是，请必须提供父母亲名字`}
                                            value={values['If Spouse is second buyer?']}
                                            onChange={handleChange}
                                            options={Object.keys(PoaYesNo).map(k => ({
                                                name: PoaYesNo[k as keyof typeof PoaYesNo],
                                                value: PoaYesNo[k as keyof typeof PoaYesNo],
                                            }))}
                                            error={errors["If Spouse is second buyer?"]}
                                        />
                                        {
                                            values['If Spouse is second buyer?'] === PoaYesNo.Yes ?
                                                <>
                                                    <FormSection
                                                        title='Spouse Parents Details'
                                                        subSection
                                                    >
                                                        <FormField
                                                            fieldType='text'
                                                            name="Spouse(Co-buyer)'s Father_First Name in Engllish"
                                                            label="Spouse(Co-buyer)'s Father First Name in Engllish"
                                                            sublabel='配偶（买家）父亲英文名字'
                                                            value={values["Spouse(Co-buyer)'s Father_First Name in Engllish"]}
                                                            onChange={handleChange}
                                                            error={errors["Spouse(Co-buyer)'s Father_First Name in Engllish"]}
                                                        />
                                                        <FormField
                                                            fieldType='text'
                                                            name="Spouse(Co-buyer)'s Father_Last Name in English"
                                                            label="Spouse(Co-buyer)'s Father Last Name in English"
                                                            sublabel='配偶（买家）父亲英文姓氏'
                                                            value={values["Spouse(Co-buyer)'s Father_Last Name in English"]}
                                                            onChange={handleChange}
                                                            error={errors["Spouse(Co-buyer)'s Father_Last Name in English"]}
                                                        />
                                                        <FormField
                                                            fieldType='text'
                                                            name="Spouse(Co-buyer)'s Mother_First Name in English"
                                                            label="Spouse(Co-buyer)'s Mother First Name in English"
                                                            sublabel='配偶（买家）母亲英文名字'
                                                            value={values["Spouse(Co-buyer)'s Mother_First Name in English"]}
                                                            onChange={handleChange}
                                                            error={errors["Spouse(Co-buyer)'s Mother_First Name in English"]}
                                                        />
                                                        <FormField
                                                            fieldType='text'
                                                            name="Spouse(Co-buyer)'s Mother_Last Name in English"
                                                            label="Spouse(Co-buyer)'s Mother Last Name in English"
                                                            sublabel='配偶（买家）母亲英文姓氏'
                                                            value={values["Spouse(Co-buyer)'s Mother_Last Name in English"]}
                                                            onChange={handleChange}
                                                            error={errors["Spouse(Co-buyer)'s Mother_Last Name in English"]}
                                                        />
                                                    </FormSection>
                                                </>
                                                : null
                                        }
                                    </FormSection>
                                </>
                                : null
                            }


                            {/* buyer parent info */}
                            <FormSection
                                title="Buyer's Parents Details"
                            >
                                <FormField
                                    fieldType='text'
                                    name="Buyer's Father_ First Name in English"
                                    label="Buyer's Father First Name in English"
                                    sublabel='买家父亲英文名字'
                                    value={values["Buyer's Father_ First Name in English"]}
                                    onChange={handleChange}
                                    error={errors["Buyer's Father_ First Name in English"]}
                                />
                                <FormField
                                    fieldType='text'
                                    name="Buyer's Father_ Last Name in English"
                                    label="Buyer's Father Last Name in English"
                                    sublabel='买家父亲英文姓氏'
                                    value={values["Buyer's Father_ Last Name in English"]}
                                    onChange={handleChange}
                                    error={errors["Buyer's Father_ Last Name in English"]}
                                />
                                <FormField
                                    fieldType='text'
                                    name="Buyer's Mother_First Name in English"
                                    label="Buyer's Mother First Name in English"
                                    sublabel='买家母亲英文名字'
                                    value={values["Buyer's Mother_First Name in English"]}
                                    onChange={handleChange}
                                    error={errors["Buyer's Mother_First Name in English"]}
                                />
                                <FormField
                                    fieldType='text'
                                    name="Buyer's Mother_Last Name in English"
                                    label="Buyer's Mother Last Name in English"
                                    sublabel='买家母亲英文姓氏'
                                    value={values["Buyer's Mother_Last Name in English"]}
                                    onChange={handleChange}
                                    error={errors["Buyer's Mother_Last Name in English"]}
                                />
                            </FormSection>

                            {/* Additional Buyers info */}
                            {
                                values['Numbers of Buyers'] === PoaNumbersOfBuyersOption.Multiple ?
                                    <FormSection
                                        title='Other Buyers Details'
                                    >
                                        <FormField
                                            fieldType='text'
                                            name="2nd Buyer's First Name In English"
                                            label="2nd Buyer's First Name In English"
                                            sublabel='第二位买家 - 英文名字'
                                            value={values["2nd Buyer's First Name In English"]}
                                            onChange={handleChange}
                                            error={errors["2nd Buyer's First Name In English"]}
                                            required={false}
                                        />
                                        <FormField
                                            fieldType='text'
                                            name="2nd Buyer's Last Name In English"
                                            label="2nd Buyer's Last Name In English"
                                            sublabel='第二位买家 - 英文姓氏'
                                            value={values["2nd Buyer's Last Name In English"]}
                                            onChange={handleChange}
                                            error={errors["2nd Buyer's Last Name In English"]}
                                            required={false}
                                        />
                                        <FormField
                                            fieldType='upload'
                                            name="Upload 2nd Buyer's Passport copy"
                                            label="Upload 2nd Buyer's Passport"
                                            sublabel='上传第二位买家护照'
                                            value={values["Upload 2nd Buyer's Passport copy"]}
                                            onChange={(files) => {
                                                setFieldValue("Upload 2nd Buyer's Passport copy", files)
                                            }}
                                            error={errors["Upload 2nd Buyer's Passport copy"]}
                                            existingResponse={existingFullResponse}
                                        />
                                        <FormSection
                                            title='2nd Buyer Parents Details'
                                            subSection
                                        >
                                            <FormField
                                                fieldType='text'
                                                name="2nd Buyer's Father_First Name in English"
                                                label="2nd Buyer's Father First Name in English"
                                                sublabel='第二位买家 - 父亲英文名字'
                                                value={values["2nd Buyer's Father_First Name in English"]}
                                                onChange={handleChange}
                                                error={errors["2nd Buyer's Father_First Name in English"]}
                                                required={false}
                                            />
                                            <FormField
                                                fieldType='text'
                                                name="2nd Buyer's Father_Last Name in English"
                                                label="2nd Buyer's Father Last Name in English"
                                                sublabel='第二位买家 - 父亲英文姓氏'
                                                value={values["2nd Buyer's Father_Last Name in English"]}
                                                onChange={handleChange}
                                                error={errors["2nd Buyer's Father_Last Name in English"]}
                                                required={false}
                                            />
                                            <FormField
                                                fieldType='text'
                                                name="2nd Buyer's Mother-First Name in English"
                                                label="2nd Buyer's Mother First Name in English"
                                                sublabel='第二位买家 - 母亲英文名字'
                                                value={values["2nd Buyer's Mother-First Name in English"]}
                                                onChange={handleChange}
                                                error={errors["2nd Buyer's Mother-First Name in English"]}
                                                required={false}
                                            />
                                            <FormField
                                                fieldType='text'
                                                name="2nd Buyer's Mother_Last Name in English"
                                                label="2nd Buyer's Mother Last Name in English"
                                                sublabel='第二位买家 - 母亲英文姓氏'
                                                value={values["2nd Buyer's Mother_Last Name in English"]}
                                                onChange={handleChange}
                                                error={errors["2nd Buyer's Mother_Last Name in English"]}
                                                required={false}
                                            />
                                        </FormSection>
                                        <FormField
                                            fieldType='text'
                                            name="3rd Buyer's First Name In Englsih"
                                            label="3rd Buyer's First Name In Englsih"
                                            sublabel='第三位买家 - 英文名字'
                                            value={values["3rd Buyer's First Name In Englsih"]}
                                            onChange={handleChange}
                                            required={false}
                                            error={errors["3rd Buyer's First Name In Englsih"]}
                                        />
                                        <FormField
                                            fieldType='text'
                                            name="3rd Buyer's Last Name in English"
                                            label="3rd Buyer's Last Name in English"
                                            sublabel='第三位买家 - 英文姓氏'
                                            value={values["3rd Buyer's Last Name in English"]}
                                            onChange={handleChange}
                                            required={false}
                                            error={errors["3rd Buyer's Last Name in English"]}
                                        />
                                        <FormField
                                            fieldType='upload'
                                            name="Upload 3rd Buyer's Passport copy"
                                            label="Upload 3rd Buyer's Passport"
                                            sublabel='上传第三位买家护照'
                                            value={values["Upload 3rd Buyer's Passport copy"]}
                                            onChange={(files) => {
                                                setFieldValue("Upload 3rd Buyer's Passport copy", files)
                                            }}
                                            error={errors["Upload 3rd Buyer's Passport copy"]}
                                            existingResponse={existingFullResponse}
                                        />
                                        <FormSection
                                            title='3rd Buyer Parents Details'
                                            subSection
                                        >
                                            <FormField
                                                fieldType='text'
                                                name="3rd Buyer's Father_First Name in English"
                                                label="3rd Buyer's Father First Name in English"
                                                sublabel='第三位买家 - 父亲英文名字'
                                                value={values["3rd Buyer's Father_First Name in English"]}
                                                onChange={handleChange}
                                                required={false}
                                                error={errors["3rd Buyer's Father_First Name in English"]}
                                            />
                                            <FormField
                                                fieldType='text'
                                                name="3rd Buyer's Father_Last Name in English"
                                                label="3rd Buyer's Father Last Name in English"
                                                sublabel='第三位买家 - 父亲英文姓氏'
                                                value={values["3rd Buyer's Father_Last Name in English"]}
                                                onChange={handleChange}
                                                required={false}
                                                error={errors["3rd Buyer's Father_Last Name in English"]}
                                            />
                                            <FormField
                                                fieldType='text'
                                                name="3rd Buyer's Mother_First Name in English"
                                                label="3rd Buyer's Mother First Name in English"
                                                sublabel='第三位买家 - 母亲英文名字'
                                                value={values["3rd Buyer's Mother_First Name in English"]}
                                                onChange={handleChange}
                                                required={false}
                                                error={errors["3rd Buyer's Mother_First Name in English"]}
                                            />
                                            <FormField
                                                fieldType='text'
                                                name="3rd Buyer's Mother_Last Name in English"
                                                label="3rd Buyer's Mother Last Name in English"
                                                sublabel='第三位买家 - 母亲英文姓氏'
                                                value={values["3rd Buyer's Mother_Last Name in English"]}
                                                onChange={handleChange}
                                                required={false}
                                                error={errors["3rd Buyer's Mother_Last Name in English"]}
                                            />
                                        </FormSection>
                                    </FormSection>
                                    : null
                            }
                        </div>
                        {/* <button type='submit'>Submit</button> */}
                    </form>
                </DialogContent>
                <DialogActions>
                    <div className='flex flex-row justify-start items-center w-full'>
                        <IonButton slot="end" size="default"
                            disabled={!dirty || !isValid}
                            form={`poa-form`}
                            type='submit'
                        >{tran("Submit")}</IonButton>
                    </div>
                </DialogActions>
            </Dialog>
            <IonLoading
                isOpen={isSubmitting}
            />
            <IonAlert
                isOpen={submitted}
                onDidDismiss={() => {
                    setSubmitted(false)
                    onClose()
                }}
                message='POA Form Submitted!'
                buttons={[
                    {
                        text: 'Finish',
                        handler: () => {
                            setSubmitted(false)
                            onClose()
                            window.location.reload()
                        }
                    }
                ]}
            />
        </>
    )
}

interface FormSectionProps {
    title: string;
    desc?: string;
    subSection?: boolean;
}
const FormSection: React.FC<PropsWithChildren<FormSectionProps>> = ({
    children, title, subSection = false
}: PropsWithChildren<FormSectionProps>) => {
    return (
        <section className={twMerge(
            `w-full flex flex-col gap-4 bg-slate-100 rounded-xl p-4`,
            subSection && 'bg-slate-200 mb-4'
        )} >
            <div>
                <h3 className='text-xl'>{title}</h3>
            </div>
            <div className='flex flex-col divide-y'>
                {children}
            </div>
        </section>
    )
}

type FieldType = 'text' | 'radio' | 'upload' | 'textarea' | 'date';
interface FormFieldProps {
    fieldType: FieldType;
    required?: boolean;
    name: string;
    label: string;
    sublabel?: string;
    value: any;
    onChange: (e: any) => void;
    options?: { name: string, value: string }[];
    error?: string | string[] | FormikErrors<File>[] | undefined;
    existingResponse?: PoaFormResponse | undefined;
}
function FormField({
    fieldType, name, value, onChange, label, sublabel, options, error, required = true, existingResponse = undefined
}: FormFieldProps) {
    let field: JSX.Element | null;
    const onDrop = useCallback((acceptedFiles: File[]) => {
        // Do something with the files
        onChange(acceptedFiles)
    }, [onChange])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    switch (fieldType) {
        case 'text':
            field = (
                <input
                    className='p-2'
                    type="text"
                    name={name}
                    value={value as string}
                    onChange={onChange}
                />
            )
            break;
        case 'radio':
            field = (
                <>
                    {options?.map(opt => (
                        <div key={`${snakeCase(name)}-${snakeCase(opt.value)}`}
                            className='flex gap-2 justify-start items-center relative'
                        >
                            <input
                                className='opacity-0 w-full h-full absolute cursor-pointer'
                                type='radio'
                                checked={value === opt.value}
                                name={name}
                                value={opt.value}
                                onChange={onChange}
                            ></input>
                            <Icon icon={value === opt.value ? 'ri:checkbox-fill' : 'ri:checkbox-blank-line'} />
                            <small>{opt.name}</small>
                        </div>
                    )) ?? null}
                </>
            )
            break;
        case 'textarea':
            field = (
                <textarea rows={5}
                    name={name}
                    value={value}
                    onChange={onChange}
                    className='resize-none p-2'
                ></textarea>
            )
            break;
        case 'upload':
            field = (
                <div className='flex gap-2 flex-col'>
                    {/* file preview */}
                    {(value && (value as any[])?.length) ?
                        <>
                            {(value as File[]).map((file, idx) => (
                                <div key={`${file.name}-${idx}`}
                                    className='bg-slate-200 p-2 rounded-md flex items-center justify-between'
                                >
                                    <small>{file.name}</small>
                                    <button
                                        onClick={() => {
                                            const newFiles = [...(value as File[])].filter((fi, i) => i !== idx);
                                            onChange(newFiles)
                                        }}
                                    >
                                        <BsTrash className='w-5 h-5' />
                                    </button>
                                </div>
                            ))}
                        </>
                        : null
                    }

                    {/* uploader */}
                    <div {...getRootProps()}
                        className='flex gap-2 flex-col'
                    >
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <>
                                    <div
                                        className={twMerge(
                                            `rounded-md border-2 border-dashed border-green-400 p-5`,
                                            `flex items-center justify-center cursor-pointer`,
                                            `transition`
                                        )}
                                    >
                                        <p>Drop the files here ...</p>
                                    </div>
                                </> :
                                <>
                                    <div
                                        className={twMerge(
                                            `rounded-md border-2 border-dashed border-slate-300 p-5`,
                                            `flex items-center justify-center cursor-pointer`,
                                            `hover:border-green-400 transition`
                                        )}
                                    >
                                        <p>{`Drag 'n' drop some files here, or click to select files`}</p>
                                    </div>
                                </>
                        }
                    </div>
                    <ExistingFilesList
                        fieldName={name}
                        files={existingResponse?.fields[name as keyof PoaFormPayload] as PoaFileResponse[]}
                    />
                </div>
            )
            break;
        case 'date':
            field = (
                <>
                    <DatePicker
                        value={dayjs(value) ?? dayjs()}
                        onChange={(v) => onChange((v as Dayjs).toDate())}
                    />
                </>
            )
            break;
        default:
            field = null;
            break;
    }
    return (
        <div className="w-full flex flex-col gap-1 form-field py-8">
            <div className='mb-3 flex flex-col'>
                <label>{label} {required && <span className='text-red-600' >*</span>}</label>
                {sublabel && <small className='whitespace-pre-line text-gray-500' >{sublabel}</small>}
            </div>
            {field}
            {error && <small className='text-red-500' >{
                Array.isArray(error) ? error.join(`\n`) : error
            }</small>}
        </div>
    );
}

interface ExistingFilesListProps {
    files: PoaFileResponse[] | undefined;
    fieldName: string;
}
function ExistingFilesList({
    files, fieldName
}: ExistingFilesListProps) {
    if (!files)
        return null

    return (
        <div
            className='w-full flex flex-col gap-2 p-2 bg-teal-600 text-white rounded-md'
        >
            <small className='font-bold'>Existing Files ({fieldName})</small>
            {
                files.map(file => (
                    <div
                        key={file.id}
                        className='flex gap-2 items-center justify-start p-2 rounded-sm bg-teal-500'
                    >
                        <small>{file.filename}</small>
                    </div>
                ))
            }
        </div>
    )
}