import { useIonRouter, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { airTableClient } from "app/Client";
import { useGlobalContext } from "app/contexts/global-context";
import { useUserContext } from "app/contexts/user-context";
import { IOrder } from "app/types/order";
import { AuthLayout } from "components/AuthLayout";
import { Content } from "components/Content";
import { LoadingModule } from "components/Global/LoadingModule";
import { SideBar } from "components/SideBar";
import PropertyListFilterModule from "components/properties/PropertyListFilterModule";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import "./MyPropertiesPage.css";
import SQ from '../app/assets/square-100.png';
import dayjs from "dayjs";
import { useDebounce } from "usehooks-ts";
import { BsSearch } from "react-icons/bs";

const MyPropertiesPage: React.FC = () => {
  const { buyerRecord } = useUserContext();
  const [orders, setOrders] = useState<IOrder[] | undefined>(undefined);

  // search value on mobile
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce<string>(value, 500);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const { setLoading, filter, setGlobalFilter: setFilter } = useGlobalContext();
  const { t: tran } = useTranslation()

  // useEffect(() => {

  // }, [buyerRecord, orders, setLoading]);

  useIonViewDidEnter(() => {
    console.log("property details > useEffect Starts");
    if (!buyerRecord || !!orders) {
      console.log("property details > useEffect End: no buyer record but have orders");
      return;
    }
    const loadBuyerOrdersById = async () => {
      setLoading(true);
      const promises: Promise<IOrder | null>[] = [];
      const ids = buyerRecord.fields["Order ID"];
      // console.log(`order ids`, ids);
      if (Array.isArray(ids)) {
        ids.map((orderId) => {
          return promises.push(airTableClient.getOrderById(orderId, buyerRecord));
        });
      }

      const orderRes = await Promise.allSettled(promises);
      console.log(promises, orderRes);
      const goodOrders = orderRes.filter((x): x is PromiseFulfilledResult<IOrder> => x.status === 'fulfilled').map(y => y.value).filter(order => order.fields.Status !== 'Swapped');
      setOrders(goodOrders);
      setLoading(false);
    };
    loadBuyerOrdersById();
  }, [buyerRecord, orders, setLoading])

  const filteredORder = (orders && orders.length) ? orders.filter(o =>
    filter.keyword ?
      (String(o.project?.fields?.["Project Name"]).toLowerCase().includes(filter.keyword.toLowerCase())) ||
      (String(o.unit?.fields?.ID).toLowerCase().includes(filter.keyword.toLowerCase()))
      : true
  ).filter(o2 =>
    filter.types.length ?
      filter.types.map(type => type.value === 'drop' ?
        o2.fields.Status === 'Dropped' :
        type.condition(o2.fields["Count (Purchaser)"]) && o2.fields.Status !== 'Dropped').some(v => v)
      : true
  ) : orders;

  useIonViewDidLeave(() => setOrders(undefined));

  useEffect(() => {
    setFilter(f => ({ ...f, keyword: debouncedValue }));
  }, [debouncedValue, setFilter]);

  // console.log(orders)

  return (
    <AuthLayout>
      <SideBar sideMenu customIcon="ci:slider-02" >
        <PropertyListFilterModule
          filter={filter}
          setFilter={setFilter}
        />
      </SideBar>
      <Content>
        <div className="pb-6 md:py-8" >
          {!filteredORder ? (
            <LoadingModule />
          ) : !filteredORder.length ? (
            <p>{tran(`No Property found.`)}</p>
          ) : (
            <>
              {/* mobile search bar */}
              <div className="relative pb-5 md:hidden">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <BsSearch className="text-primary" />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className={twMerge(
                      `block w-full p-2 pl-10 text-sm text-gray-900`,
                      `border border-gray-300 rounded-full bg-gray-50`,
                      `focus:ring-primary focus:border-primary`,
                    )}
                    placeholder={tran("Quick Search")}
                    value={value}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* orders */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 h-full gap-8">
                {filteredORder.map((order) => (
                  <OrderItem order={order} key={order.id} />
                ))}
                <div className="w-full md:h-auto rounded-2xl flex flex-col items-center justify-center p-4 relative bg-slate-800">
                  <img src={SQ}
                    className="opacity-0 w-full h-auto hidden md:block" alt='placeholder'
                  />
                  <img src="/assets/temp/my properties.jpg" alt=""
                    className="absolute w-full h-full object-cover rounded-2xl opacity-50 top-0 left-0"
                  />
                  <div className="w-full h-full flex flex-col items-center justify-center gap-4 relative md:absolute top-0 left-0 p-4" >
                    <h3 className="font-medium text-2xl h-full flex flex-col items-center justify-center text-center text-white relative">{tran(`Coming Soon`)}</h3>
                    <button className={twMerge(`mt-4 md:self-end justify-self-end`, `rounded-full bg-primary text-white py-1 px-4 text-sm relative`)}>{tran(`Learn more`)}</button>
                  </div>
                </div>
              </div>

            </>
          )}
        </div>
      </Content>
    </AuthLayout>
  );
};

export default MyPropertiesPage;

interface OrderItemProps {
  order: IOrder;
  className?: string;
}
export const OrderItem: React.FC<OrderItemProps> = ({ order, className }: OrderItemProps) => {
  const { push } = useIonRouter();
  const unitSplitted = order.fields["Unit Number"].trim().split(` `);
  const displayUnit = unitSplitted[unitSplitted.length - 1];
  const headBg = order.fields["Count (Purchaser)"] > 1 ? `bg-primary` : `bg-secondary`;
  const hasPoa: number = ((order.poa?.length || 0) <= 0) ? 1 : 0;
  const finalPaymentDue: number = (
    (order.fields.Status !== 'Completed') &&
    (dayjs(order.fields["Last Payment due date"]).diff(dayjs(), 'days') < 30)
  ) ? 1 : 0;
  const alertCount: number = hasPoa + finalPaymentDue + 0;

  return (
    <div
      className={twMerge(
        `rounded-2xl border border-slate-200 bg-white p-3 flex md:flex-col transition relative`,
        `flex-row md:min-h-[200px]`,
        order.fields.Status === 'Dropped' ? `` : `cursor-pointer hover:bg-slate-200`,
        className ?? ''
      )}
      onClick={() => {
        if (order.fields.Status === 'Dropped')
          return

        push(`/my-properties/${order.id}`)
      }}
    >
      {/* alert badge */}
      {alertCount > 0 ?
        <span
          className="w-6 h-6 text-sm rounded-full bg-red-500 absolute z-20 flex felx-col items-center justify-center -right-2 -top-2 text-white shadow-lg"
        >
          <b>{alertCount}</b>
        </span>
        : null
      }

      <div className="md:grow rounded-xl overflow-hidden relative flex flex-col">
        <div className={twMerge(
          `hidden md:block w-full relative p-1 md:p-2 text-white text-center text-sm md:text-3xl font-semibold`, headBg
        )}>{displayUnit}</div>
        <div
          className={twMerge(
            `w-28 grow-0 relative h-auto`,
            `object-cover object-center md:w-full md:grow rounded-xl md:rounded-none overflow-hidden`
          )}
        >
          <div className={twMerge(
            `md:hidden w-full absolute p-1 md:p-2 bg-primary text-white text-center text-sm md:text-xl font-semibold z-20`
          )}>{displayUnit}</div>

          <div className="w-full h-auto relative">
            <img src={SQ}
              className="opacity-0 w-full h-auto" alt='placeholder'
            />
            {
              order.project?.fields?.Photos?.[0]?.thumbnails?.large?.url ?
                <img
                  alt=''
                  src={order.project.fields.Photos[0].thumbnails.large.url}
                  className={twMerge(
                    `w-full h-full absolute top-0 left-0`,
                    `object-cover object-center rounded-xl md:rounded-b-xl md:rounded-t-none`
                  )}
                /> :
                // <div
                //   className={twMerge(
                //     `w-full h-full md:h-[150px] grow-0 absolute md:grow`,
                //     `object-cover object-center rounded-xl md:rounded-none bg-slate-200`
                //   )}
                // ></div>
                <img
                  alt=''
                  src="assets/temp/homepage.jpg"
                  className={twMerge(
                    `w-full h-full absolute top-0 left-0`,
                    `object-cover object-center rounded-xl md:rounded-b-xl md:rounded-t-none`
                  )}
                />
            }
          </div>

          <small
            className={twMerge(
              `hidden md:block py-0 px-2 text-sm absolute top-2 left-2 bg-teal-500 text-white rounded shadow`,
              order.fields.Status === 'Dropped' ? 'bg-slate-500' : ''
            )}
          >{order.fields.Status}</small>
        </div>
      </div>
      <div className="px-2">
        {/* mobile status label */}
        <small
          className={twMerge(
            `md:hidden py-0 px-2 text-sm top-2 left-2 bg-teal-500 text-white rounded shadow`,
            order.fields.Status === 'Dropped' ? 'bg-slate-500' : ''
          )}
        >{order.fields.Status}</small>
        <p className="flex items-center p-0 text-start md:text-center w-full md:pt-2 uppercase font-semibold text-primary leading-tight lg:text-xl break-all">{order.project?.fields?.["Project Name"]}</p>
      </div>
    </div>
  );
};
