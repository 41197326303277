import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";



interface Props {
  className?: string;
}

const Skeleton:React.FC<PropsWithChildren<Props>> = ({
    className, children
}:PropsWithChildren<Props>) =>{
  return (
    <div className={twMerge(
        `bg-white opacity-20 animate-pulse`,
        className ?? ``
    )}>
      {children}
    </div>
  )
}

export default Skeleton