import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}

export function Content({ children, className }: PropsWithChildren<Props>) {
  return <div className={twMerge(`col-span-12 md:col-span-9 w-full h-max grow md:overflow-scroll p-4 md:p-0 md:pr-8 flex flex-col`, className ?? "")}>{children}</div>;
}
