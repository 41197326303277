
export interface PropertyType {
    typeName: string;
    color: string;
    value: string;
    condition: (field: any) => boolean;
}

export const propertyTypes: PropertyType[] = [
    {
        typeName: 'Co-own',
        color: 'bg-primary',
        value: 'co-own',
        condition: (f) => f > 1,
    },
    {
        typeName: 'Self-own',
        color: 'bg-secondary',
        value: 'self-own',
        condition: (f) => f <= 1,
    },
    {
        typeName: 'Dropped',
        color: 'bg-slate-400',
        value: 'drop',
        // this is TBC
        condition: (f) => f === 'Dropped',
    }
]

export interface PropertiesFilter {
    keyword: string;
    types: PropertyType[];
}

export const defaultFilter: PropertiesFilter = {
    keyword: '',
    types: [
        propertyTypes[0], propertyTypes[1]
    ]
}