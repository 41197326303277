import { IonContent, IonPage } from "@ionic/react";
import { useUserContext } from "app/contexts/user-context";
import { FC, PropsWithChildren, useEffect } from "react";
import { useHistory } from "react-router";
import { CenterContainer } from "./Global/CenterContainer";

interface Props extends PropsWithChildren {
  className?: string;
}

const GuestLayout: FC<Props> = ({ children, className }) => {
  const { user, buyerRecord, userDocs, multiUserSelected } = useUserContext();
  const { push } = useHistory();

  useEffect(() => {
    // console.log('Guest Layout Guard');
    if ((user && buyerRecord)) {
      // console.log('push to home');
      push("/");
    }else if ((userDocs && userDocs.length > 1 && !multiUserSelected)) {
      // console.log('push to gateway');
      push('/user-gateway')
    }else{
      // console.log('render guest layout children');
    }
  }, [user, buyerRecord, userDocs, multiUserSelected, push])

  return (
    <IonPage>
      <IonContent fullscreen>
        <CenterContainer className={className ?? ''} >{children}</CenterContainer>
      </IonContent>
    </IonPage>
  );
};
export default GuestLayout;
