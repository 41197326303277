import { yupResolver } from "@hookform/resolvers/yup";
import { IonContent, IonLoading, IonPage } from "@ionic/react";
import { useUserContext } from "app/contexts/user-context";
import { DB, firebaseCollections } from "app/contexts/user-context";
import { yup } from "app/helpers";
import { useSearchParams } from "app/hooks/useSearchParams";
import { IBuyerInvitation, IBuyerInvitationStatus } from "app/types/buyerInvitation";
import { IUserData } from "app/types/user";
import { CenterContainer } from "components/Global/CenterContainer";
import LoadingScreen from "components/Global/LoadingScreen";
import { passwordRegex } from "components/my-account-views/ResetPasswordView";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiFillAlert, AiFillCheckCircle } from "react-icons/ai";
import { useHistory } from "react-router";
import { twMerge } from "tailwind-merge";

interface Params {
  email: string | undefined | null;
  token: string | undefined | null;
}

interface IFormInput {
  password: string;
  retypePassword: string;
}

export default function RegisterPage() {
  const { t: tran } = useTranslation();

  const schema = yup.object({
    password: yup.string().required(tran(`Password is required`)),
    retypePassword: yup.string().required(tran(`Password is not the same`)),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const values = watch();

  const { push } = useHistory();
  const { signup, createUserDocWithExistingAuth } = useUserContext();
  const searchParams = useSearchParams();
  const params: Params = useMemo(() => ({
    email: searchParams.get("email"),
    token: searchParams.get("token"),
  }), [searchParams]);

  const [invitation, setInvitation] = useState<IBuyerInvitation | undefined | null>(undefined);
  const [registering, setRegistering] = useState(false);
  const [existingAuthUid, setExistingAuthUid] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(true);

  const checkInvite = async (params: Params) => {
    const { email, token } = params;
    console.log(params)
    // return
    if (!email || !token) {
      setInvitation(null);
      return;
    }
    const invitationDocs = await getDocs(query(collection(DB, firebaseCollections.invitations), where("email", "==", email), where("token", "==", token), limit(1)));
    // const existingUserDocCount = await getCountFromServer(query(collection(DB, firebaseCollections.users), where("email", "==", email)));

    if (invitationDocs.empty || invitationDocs.size !== 1
      // || existingUserDocCount.data().count
    ) {
      setInvitation(null);
      return;
    }
    const invitationDoc = invitationDocs.docs[0];
    const invitation = { id: invitationDoc.id, ...invitationDoc.data() } as IBuyerInvitation;
    // console.log(`got invitation`, invitation)

    // needs to check if the very same email has already been registered with another auth
    const userDocs = await getDocs(query(
      collection(DB, `users`),
      where('email', '==', email),
      where('role', '==', 'buyer')
    ));
    if (!userDocs.empty) {
      // if the auth user already exist, we need to create user doc but not signup for auth user
      const zeroUser = userDocs.docs[0].data() as IUserData
      setExistingAuthUid(zeroUser.uid);
    }

    setInvitation(invitation);
  };

  useEffect(() => {
    checkInvite(params);
  }, [params]);

  const checkPw = useCallback(() => {
    if (!values.password || !passwordRegex.test(values.password) || values.password !== values.retypePassword) {
      setDisabled(true)
      return
    }
    setDisabled(false)
  }, [values, setDisabled]);

  useEffect(() => {
    checkPw()
  }, [values, checkPw])

  const onSubmit: SubmitHandler<IFormInput> = useCallback(async (data) => {
    // create new user
    if (!invitation) {
      return;
    }
    setRegistering(true)
    await signup(invitation, data.password);
    const { email, token } = params;
    const invitationDocs = await getDocs(query(collection(DB, firebaseCollections.invitations), where("email", "==", email), where("token", "==", token), limit(1)));
    const invitationDoc = invitationDocs.docs[0];
    const updatedInvitation = { id: invitationDoc.id, ...invitationDoc.data() } as IBuyerInvitation;
    setInvitation(updatedInvitation);
    setRegistering(false)
    console.log(data);
    // window.location.assign('/login')
  }, [invitation, params, signup]);

  // function to only create user doc instead of create auth user
  const handleAddBuyer = useCallback(async () => {
    if (!invitation || !existingAuthUid)
      return;

    setRegistering(true)
    await createUserDocWithExistingAuth(invitation, existingAuthUid)
    const { email, token } = params;
    const invitationDocs = await getDocs(query(collection(DB, firebaseCollections.invitations), where("email", "==", email), where("token", "==", token), limit(1)));
    const invitationDoc = invitationDocs.docs[0];
    const updatedInvitation = { id: invitationDoc.id, ...invitationDoc.data() } as IBuyerInvitation;
    setInvitation(updatedInvitation);
    setRegistering(false);
  }, [invitation, existingAuthUid, params, createUserDocWithExistingAuth]);

  // if(user){
  //   // push('/');
  //   // window.location.assign()
  // }

  if (invitation === undefined) {
    return (
      <IonPage>
        <IonContent fullscreen>
          <LoadingScreen />
        </IonContent>
      </IonPage>
    );
  }

  if (invitation === null) {
    return (
      <IonPage>
        <IonContent fullscreen>
          <CenterContainer className="text-center max-w-3xl m-auto">
            <AiFillAlert className="w-20 h-20 text-red-400" />
            <h1 className="text-xl font-bold text-primary" >{(`The Invitation is either invalid or already expired.\n邀请已失效或已过期。`)}</h1>
            <p>
              {(`Please contact`)}{" "}
              <a className="text-primary underline" href="mailto:support@fulcrum.com">
                Support@Flucrum.com
              </a>{" "}
              {(`for a new invitation.`)}
            </p>
            <p>
              {(`请联系`)}{" "}
              <a className="text-primary underline" href="mailto:support@fulcrum.com">
                Support@Flucrum.com
              </a>{" "}
              {(`获取新的邀请。`)}
            </p>
            <button className="px-4 py-2 bg-slate-200 text-slate-700 rounded w-full" onClick={() => push("/")}>
              {(`Back 返回`)}
            </button>
          </CenterContainer>
        </IonContent>
      </IonPage>
    );
  }

  if (invitation && invitation.status === IBuyerInvitationStatus.Active) {
    return (
      <IonPage>
        <IonContent fullscreen>
          <CenterContainer className="text-center max-w-3xl m-auto">
            <AiFillCheckCircle className="w-20 h-20 text-primary" />
            <h1 className="text-xl font-bold text-primary">
              {`You've already registered with this invitation. Please login to use the portal.`}
              <br />
              {`你已完成登记，请重新登录`}
            </h1>
            <a className="px-4 py-2 bg-slate-200 text-primary rounded w-full" href="/login">
              {(`Login 登录`)}
            </a>
          </CenterContainer>
        </IonContent>
      </IonPage>
    )
  }

  // if user already authed from other invites
  if (invitation && existingAuthUid) {
    return (
      <IonPage>
        <IonContent fullscreen>
          <CenterContainer className="max-w-4xl m-auto">
            <div
              className="max-w-sm mb-8"
            >
              <img src="./assets/logos/20240110_FGP Logotype Media Usage_Primary Logo.png" alt="fulcrum" />
            </div>
            <div className="flex flex-col gap-4 p-4 text-center max-w-xl mx-auto">
              <h3>{(`Welcome 欢迎`)}, {invitation?.name}</h3>
              <p className="text-sm">
                {`Thank you for using Fulcrum Property Management App.`}
                <br />
                {`Your email address ${invitation.email} was already registered as more than 1 buyers, please confirm if you want to add another buyer to manage with this email?`}
              </p>
              <p className="text-sm">
                {`感谢您使用 Fulcrum 业主系统。`}
                <br />
                {`您的电子邮件地址 ${invitation.email} 已经注册为多个买家，请确认是否要添加另一个买家来使用此电子邮件进行管理？`}
              </p>
            </div>
            <div className="w-full p-4 rounded-md bg-slate-100 max-w-xl mx-auto">
              <button className="py-2 px-4 w-full rounded bg-primary text-white mt-4"
                onClick={handleAddBuyer}
              >{(`+ ${invitation.name}`)}</button>
              <a className="p-4 w-full bg-transparent text-slate-400 text-center flex justify-center"
                href={'/login'}
              >{tran(`Cancel`)}</a>
            </div>
          </CenterContainer>
        </IonContent>
        <IonLoading isOpen={registering} />
      </IonPage>
    )
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <CenterContainer className="max-w-4xl m-auto">
          <div
            className="max-w-sm mb-8"
          >
            <img src="./assets/logos/20240110_FGP Logotype Media Usage_Primary Logo.png" alt="fulcrum" />
          </div>
          <div className="flex flex-col gap-4 p-4 text-center">
            <h3>{(`Welcome 欢迎`)}, {invitation?.name}</h3>
            <p className="text-sm" >{(`Please complete the registration by setting up your password.`)}</p>
            <p className="text-sm">{(`请设置您的密码以完成注册。`)}</p>
          </div>
          <div className="w-full p-4 rounded-md bg-slate-100">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <small>{tran(`Password 密码`)}</small>
                <input
                  type="password"
                  defaultValue=''
                  {...register("password")}
                  className="bg-white block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <p>{errors.password?.message}</p>
              </div>
              <div className="mt-2">
                <small>{tran(`Retype Password 重新输入密码`)}</small>
                <input
                  type="password"
                  defaultValue=''
                  {...register("retypePassword")}
                  className="bg-white block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <p>{errors.retypePassword?.message}</p>
              </div>
              <input type="submit" value="Submit" className="py-2 px-4 w-full rounded bg-primary text-white mt-4 disabled:bg-slate-200 disabled:text-slate-400" disabled={disabled} />

              <div className="mt-8 max-w-xl m-auto" >
                <small
                  className={twMerge(
                    disabled ? 'text-red-500' : 'text-green-700'
                  )}
                >Hint: Your new password should be 8 characters long and include at least 1 number, 1 uppercase letter, and 1 special character (e.g., !, @, #, $, etc.). Make it strong and unique for better security.
                </small><br />
                <small
                  className={twMerge(
                    disabled ? 'text-red-500' : 'text-green-700'
                  )}
                >提示：您的新密码应为8个字符长，包括至少1个数字、1个大写字母和1个特殊字符（例如！、@、#、$等）。请确保密码强大且独一无二以提高安全性。</small>
              </div>
            </form>
          </div>
          <a className="p-4 w-full bg-transparent text-slate-400"
            href={'/login'}
          >{tran(`Cancel`)}</a>
        </CenterContainer>
      </IonContent>
      <IonLoading isOpen={registering} />
    </IonPage>
  );
}
