export enum TABLE_NAMES{
    Buyers = `🛒 Buyers`,
    Orders = `🚀 Orders`,
    Transactions = `💰 Transactions`,
    SpecialArrangements = `🚩 Special Arrangements`,
    Solicitors = "Solicitors",
    SATypes = "SA Types",
    SolicitorContacts = "Solicitor Contacts",
    AfterSalesServiceLogbook = "After Sales Service Logbook",
    Notes = `📗 Notes`,
    LocalProjects = `🌍 Local Projects`,
    LaunchPromotionSchedule = `📆 Launch& Promotion Schedule`,
    LocalInventory = `⛩ Local Inventory`,
    SyncChannels = `Sync Channels`,
    StaffList = `Staff List`,
    // POA form is from another base
    POA = "POA Details Form"
}