import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { CenterContainer } from "components/Global/CenterContainer";
import TermsAndConditionsView from "components/my-account-views/TermsAndConditionsView";
import { BsChevronLeft } from "react-icons/bs";


export function PublicTermsPage() {
    const { push } = useIonRouter()
    return (
        <IonPage>
            <IonContent fullscreen>
                <CenterContainer className="h-auto px-0" >
                    <div
                        className="pt-2 px-3 flex w-full"
                    >
                        <button
                            className="py-1 px-3 bg-primary text-white rounded flex gap-2 items-center transition group"
                            onClick={() => push('/login')}
                        >
                            <BsChevronLeft
                                className="group-hover:-translate-x-1 transition"
                            /><span>Back</span>
                        </button>
                    </div>
                    <TermsAndConditionsView />
                </CenterContainer>
            </IonContent>
        </IonPage>
    )
}