import { GlobalContextProvider } from "app/contexts/global-context";
import { UserProvider } from "app/contexts/user-context";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs";
import './i18n';


// temporary const for chat demo
interface ChatServiceSettings {
  sleekflow: boolean,
  tidio: boolean,
  respondIO: boolean,
  zoho: boolean
}
export const chatSettings: ChatServiceSettings = {
  sleekflow: false, tidio: false, respondIO: true, zoho: false
}

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <UserProvider>
    <GlobalContextProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <App />
    </LocalizationProvider>
    </GlobalContextProvider>
  </UserProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
