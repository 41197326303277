import { useIonRouter } from "@ionic/react";
import useContentLocales from "app/contexts/use-content-locale";
import { DB } from "app/contexts/user-context";
import { IService } from "app/types/services";
import { AuthLayout } from "components/AuthLayout";
import { Content } from "components/Content";
import { LoadingModule } from "components/Global/LoadingModule";
import { SideBar } from "components/SideBar";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

const ValueAddedServicesMain: React.FC = () => {
  const [rawServices, setServices] = useState<IService[] | undefined | null>([]);
  const { push } = useIonRouter();
  const { renderLocalizedData } = useContentLocales();
  const { t: tran } = useTranslation();

  useEffect(() => {
    const loadServices = async () => {
      const res = await getDocs(collection(DB, `services`));
      const services = res.docs.map(dd => ({ id: dd.id, ...dd.data() } as IService)).filter(s => s.publish);
      setServices(services);
    }
    loadServices();
  }, []);

  const services = !rawServices ? rawServices : rawServices.map(ser => ({
    ...renderLocalizedData<IService>(ser, ser.locales ?? [])
  } as IService))

  return (
    <AuthLayout>
      <SideBar></SideBar>
      <Content>
        <section className="flex flex-col w-full gap-4 md:py-8">
          <div className="w-full h-80">
            <div className="relative w-full h-full bg-slate-300 rounded-md flex flex-col justify-center items-center p-4 relative bg-slate-800">
              <img src="/assets/temp/VAS.JPG"
                alt=""
                className="absolute w-full h-full object-cover rounded-md opacity-50"
              />
              <h3 className="font-medium text-2xl h-full flex flex-col items-center justify-center text-white text-center relative">{tran('Coming Soon')}</h3>
              <button className={twMerge(`self-end justify-self-end`, `rounded-full bg-primary text-white py-1 px-4 text-sm relative`)}>{tran(`Apply Now`)}</button>
            </div>
          </div>

          <div className="md:py-4">
            {
              !services ? <LoadingModule /> :
                <div className="w-full relative overflow-visible">
                  <div className="relative w-full grid grid-cols-1 md:grid-cols-3 gap-4 overflow-visible">
                    {services.sort((a, b) => (a.index ?? 0) < (b.index ?? 0) ? -1 : 1).map((service) => (
                      <div
                        key={service.id}
                        data-index={service.index}
                        className="cursor-pointer w-full h-60 bg-gradient-to-b from-primary to-[#65A4A7] rounded-md snap-start flex items-center justify-center"
                        onClick={() => push(`/services/${service.slug}`)}
                      >
                        <div className="flex flex-col items-center max-w-[80%] text-center gap-4 hover:scale-105 transition">
                          {service.icon.src &&
                            <img src={service.icon.src} alt=""
                              className="w-24 h-24 object-cover"
                            />
                          }
                          <h5 className="text-white">{service.name}</h5>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
            }
          </div>
        </section>
      </Content>
    </AuthLayout>
  );
};

export default ValueAddedServicesMain;
