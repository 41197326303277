import { useIonRouter, useIonViewWillEnter } from "@ionic/react";
import { useGlobalContext } from "app/contexts/global-context";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { twMerge } from "tailwind-merge";

interface NavItemProps {
  title: string;
  path: string;
  reload?: boolean;
}

interface Props {
  sideMenu?: boolean;
  customIcon?: string;
}

const isActivePath = (path: string, currentPaths: string) => {
  const currentPathsArr = currentPaths.split('/').filter(x => x);
  return path === '/' ? currentPaths === '/' : currentPathsArr.includes(path.replaceAll('/', ''));
}

export function SideBar({ children, sideMenu = false, customIcon }: PropsWithChildren<Props>) {
  const { pathname } = useLocation();
  const { push } = useIonRouter();
  const { setMobileSideProps, setCustomSideIcon } = useGlobalContext();
  const { t: tran } = useTranslation();

  useIonViewWillEnter(() => {
    if (sideMenu && children) {
      setMobileSideProps(children);
    } else {
      setMobileSideProps(null);
    }

    setCustomSideIcon(customIcon)
  }, []);

  const NavItem: React.FC<NavItemProps> = ({ title, path, reload = false }: NavItemProps) => {
    return (
      <div
        onClick={() => {
          reload ? window.location.assign(path) : push(path)
        }}
        className={twMerge(
          `relative pt-3 pb-1.5 border-primary text-slate-500 font-semibold`,
          `transition`,
          isActivePath(path, pathname) ? `border-b text-primary` : "cursor-pointer hover:translate-x-2 hover:text-primary"
        )}
      >
        {title}
      </div>
    );
  };

  return (
    <>
      {/* desktop */}
      <aside className={twMerge(`col-span-3 hidden md:flex flex-col gap-8 h-full relative`)}>
        <nav className="w-full flex flex-col gap-2 px-4">
          <NavItem title={tran("Home")} path="/" />
          <NavItem title={tran("My Properties")} path="/my-properties" />
          <NavItem title={tran("Value-Added Services")} path="/services" />
          <NavItem title={tran("Notifications")} path="/notifications" />
          <NavItem title={tran("My Account")} path="/account/guideline" />
        </nav>
        <div className="relative grow h-[400px] overflow-y-scroll">{children}</div>
      </aside>
    </>
  );
}
