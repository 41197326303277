import useContentLocales from "app/contexts/use-content-locale";
import { DB } from "app/contexts/user-context";
import { IPrivacy } from "app/types/legals";
import LoadingScreen from "components/Global/LoadingScreen";
import dayjs from "dayjs";
import { doc, getDoc } from "firebase/firestore";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function TermsAndConditionsView() {

  const [rawPp, setRawPp] = useState<IPrivacy | undefined>(undefined);
  const { renderLocalizedData } = useContentLocales();
  const { t: tran } = useTranslation();

  useEffect(() => {
    const getTnc = async () => {
      const res = await getDoc(doc(DB, `legal-contents/privacy-policy`));
      setRawPp({ id: res.id, ...res.data() } as IPrivacy);
    }

    getTnc();
  }, [])

  const pp = !rawPp ? undefined : renderLocalizedData<IPrivacy>(rawPp, rawPp?.locales ?? []);

  return (
    <section>
      {
        !rawPp ? <LoadingScreen /> :
          <>
            <div className="flex h-60 justify-center">
              <img src={pp?.cover.src} alt={pp?.cover.alt} className="h-full w-full object-cover" />
            </div>
            <div className="bg-slate-100 h-full p-6">
              <div className="md:h-20 flex items-center justify-between flex flex-col md:flex-row gap-4 mb-8">
                <h6 className="text-2xl font-bold text-primary">{tran(`Privacy Policy`)}</h6>
                <h6>Last updated: {dayjs(pp?.lastUpdated).format("DD-MM-YYYY")}</h6>
              </div>
              <div style={{ whiteSpace: "pre-wrap" }}>{parse(pp?.content ?? '')}</div>
            </div>
          </>
      }
    </section>
  );
}

