import useContentLocales from "app/contexts/use-content-locale";
import { DB } from "app/contexts/user-context";
import { ITnC } from "app/types/legals";
import LoadingScreen from "components/Global/LoadingScreen";
import dayjs from "dayjs";
import { doc, getDoc } from "firebase/firestore";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
}

export default function TermsAndConditionsView({ className }: Props) {

  const [rawTnc, setRawTnc] = useState<ITnC | undefined>(undefined);
  const { renderLocalizedData } = useContentLocales();
  const { t: tran } = useTranslation();

  useEffect(() => {
    const getTnc = async () => {
      const res = await getDoc(doc(DB, `legal-contents/terms-and-conditions`));
      setRawTnc({ id: res.id, ...res.data() } as ITnC);
    }

    getTnc();
  }, [])

  const tnc = !rawTnc ? undefined : renderLocalizedData<ITnC>(rawTnc, rawTnc?.locales ?? []);

  return (
    <section>
      {
        !rawTnc ? <LoadingScreen /> :
          <>
            <div className="flex h-60 justify-center">
              <img src={tnc?.cover.src} alt={tnc?.cover.alt} className="h-full w-full object-cover" />
            </div>
            <div className="bg-slate-100 h-full p-6">
              <div className="md:h-20 flex items-center justify-between flex flex-col md:flex-row gap-4 mb-8">
                <h6 className="text-2xl font-bold text-primary">{tran(`Terms & Conditions`)}</h6>
                <h6>Last updated: {dayjs(tnc?.lastUpdated).format("DD-MM-YYYY")}</h6>
              </div>
              <div style={{ whiteSpace: "pre-wrap" }}>{parse(tnc?.content ?? '')}</div>
            </div>
          </>
      }
    </section>
  );
}

