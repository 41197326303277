import { IonAlert, IonLoading } from "@ionic/react";
import { useUserContext } from "app/contexts/user-context";
import { Content } from "components/Content";
import { getAuth, signOut } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

export const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[\W_]).{8,}$/;

interface IPW {
  currentPw: string;
  pw: string;
  retype: string;
}

function ResetPasswordView() {
  const [pw, setPw] = useState<IPW>({
    currentPw: '', pw: "", retype: ""
  });

  const [disabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState<'idle' | 'saving' | 'saved'>('idle');
  const { resetPassword } = useUserContext();
  const { t: tran } = useTranslation();

  const checkPw = useCallback(() => {
    if (!pw.pw || !passwordRegex.test(pw.pw) || pw.pw !== pw.retype) {
      setDisabled(true)
      return
    }
    setDisabled(false)
  }, [pw, setDisabled])

  useEffect(() => {
    checkPw()
  }, [pw, checkPw])

  const handleSubmit = useCallback(async () => {
    setSaving('saving');
    await resetPassword(pw.currentPw, pw.pw);
    setSaving('saved');
  }, [setSaving, pw, resetPassword])

  return (
    <>
      <Content>
        <div
          className="w-full p-8 flex justify-center items-center flex-col gap-8"
        >
          <h2 className="text-xl font-sans" >{tran(`Reset Password`)}</h2>
          <div
            className="bg-slate-100 rounded-xl p-8 w-fit flex flex-col gap-5 max-w-md"
          >
            <div className="flex flex-col gap-2" >
              <label>{tran(`Current Password`)}</label>
              <input type='password' className="p-2"
                onChange={e => setPw(p => ({ ...p, currentPw: e.target.value }))}
              />
            </div>
            <div className="flex flex-col gap-2" >
              <label>{tran(`New Password`)}</label>
              <input type='password' className="p-2"
                onChange={e => setPw(p => ({ ...p, pw: e.target.value }))}
              />
            </div>
            <div className="flex flex-col gap-2" >
              <label>{tran(`Confirm New Password`)}</label>
              <input type='password' className="p-2"
                onChange={e => setPw(p => ({ ...p, retype: e.target.value }))}
              />
            </div>
            <div>
              <small
                className={twMerge(
                  disabled ? 'text-red-500' : 'text-green-700'
                )}
              >
                {tran(
                  `Hint: Your new password should be 8 characters long and include at least 1 number, 1 uppercase letter, and 1 special character (e.g., !, @, #, $, etc.). Make it strong and unique for better security.`
                )}
              </small>
            </div>

            <button
              disabled={disabled}
              className={twMerge(
                `bg-teal-500 text-white rounded-md p-2`,
                `disabled:bg-slate-200 disabled:text-slate-400`
              )}
              onClick={handleSubmit}
            >{tran(`Update Password`)}</button>
          </div>
        </div>
        <IonLoading isOpen={saving === 'saving'} />
      </Content>
      <IonAlert
        isOpen={saving === 'saved'}
        header={tran("Your new password has been updated.")}
        onDidDismiss={() => setSaving('idle')}
        buttons={[
          {
            text: tran('Login Again'),
            handler: async () => {
              await signOut(getAuth());
              window.location.assign('/login');
            }
          }
        ]}
      ></IonAlert>
    </>
  );
}

export default ResetPasswordView;
