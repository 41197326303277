import { PropertiesFilter, defaultFilter } from "app/types/property";
import { IPushNotification } from "app/types/pushNotification";
import React, { Dispatch, useCallback, useContext, useEffect, useState } from "react";
import { useUserContext } from "./user-context";
import { Unsubscribe, collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { DB } from "app/contexts/user-context";

// Step 1: Define the context
interface GlobalContextType {
  loading: boolean;
  setLoading: (b: boolean) => void;
  mobileMenu: boolean;
  setMobileMenu: (b: boolean) => void;
  mobileSideProps: React.ReactNode | null;
  setMobileSideProps: (node: React.ReactNode) => void;
  customSideIcon: string | undefined;
  setCustomSideIcon: (s: string | undefined) => void;
  // I need to sent the property filter to here as global context
  filter: PropertiesFilter,
  setGlobalFilter: Dispatch<React.SetStateAction<PropertiesFilter>>;
  pushes: IPushNotification[] | undefined;
}

// Step 2: Create the context object
export const GlobalContext = React.createContext<GlobalContextType | undefined>(undefined);

// Step 3: Create a provider component
type Props = {
  children: React.ReactNode;
};
export function GlobalContextProvider({ children }: Props) {
  const [loading, setLoadingState] = useState(false);
  const [mobileMenu, setMobileMenuState] = useState(false);
  const [mobileSideProps, setMobileSidePropsState] = useState<React.ReactNode | null>(null);
  const [filter, setFilter] = useState<PropertiesFilter>(defaultFilter);
  const [pushes, setPushes] = useState<IPushNotification[] | undefined>(undefined);
  const [customSideIcon, setCustomSideIconState] = useState<string | undefined>(undefined);
  const { user } = useUserContext();


  const setLoading = useCallback((b: boolean) => setLoadingState(b), []);
  const setMobileMenu = useCallback((b: boolean) => setMobileMenuState(b), []);
  const setMobileSideProps = useCallback((node: React.ReactNode) => setMobileSidePropsState(node), []);
  const setCustomSideIcon = useCallback((s: string | undefined) => setCustomSideIconState(s), []);

  const setGlobalFilter = useCallback(setFilter, [setFilter]);

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    
    if (user) {
      const pushesQuery = query(
        collection(DB, `push-notifications`),
        where('user.id', '==', user?.id),
        orderBy('date', 'desc')
      )
      unsubscribe = onSnapshot(pushesQuery, (sss) => {
        setPushes(sss.docs.map(ss => ({id: ss.id, ...ss.data()} as IPushNotification)))
      });
    }

    return () => {
      if(unsubscribe)
      unsubscribe();
    }
  }, [user])

  const contextValue: GlobalContextType = {
    loading,
    setLoading,
    mobileMenu,
    setMobileMenu,
    mobileSideProps,
    setMobileSideProps,
    filter, setGlobalFilter,
    pushes,
    customSideIcon, setCustomSideIcon
  };

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
}

// Step 4: Create a consumer hook
export const useGlobalContext = (): GlobalContextType => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
