import { IonContent, IonPage } from "@ionic/react";
import { useUserContext } from "app/contexts/user-context";
import { FC, PropsWithChildren, useEffect } from "react";
import { useHistory } from "react-router";
import { CenterContainer } from "./Global/CenterContainer";

interface Props extends PropsWithChildren {
  className?: string;
}

const MultiSelectLayout: FC<Props> = ({ children, className }) => {
  const { user, buyerRecord } = useUserContext();
  const { push } = useHistory();

  useEffect(() => {
    console.log('Guest Layout Guard');
    if (user && buyerRecord) {
      console.log('push to home');
      push("/");
    } else {
      console.log('render multi select layout children');
    }
  }, [user, buyerRecord, push])

  return (
    <IonPage>
      <IonContent fullscreen>
        <CenterContainer className={className ?? ''} >{children}</CenterContainer>
      </IonContent>
    </IonPage>
  );
};
export default MultiSelectLayout;
