import { IonLoading, IonToast } from "@ionic/react";
import { useUserContext } from "app/contexts/user-context";
import GuestLayout from "components/GuestLayout";
import dayjs from "dayjs";
import logoWhite from "media/logoWhite.png";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaLock, FaUser } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { twMerge } from "tailwind-merge";

export default function LoginPage() {
  const { login } = useUserContext();
  const [values, setValues] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { t: tran, i18n } = useTranslation()

  const incorrectPwString = tran('Incorrect email / password, please try again.');
  const emailPlaceholder = tran("User ID / Email");
  const pwPlaceHolder = tran(`Password`);

  const handleSubmit = useCallback(async () => {
    // console.log("login start", values);
    if (!values.email || !values.password) {
      return;
    }
    setLoading(true);
    const loginResult = await login(values.email, values.password);
    if (loginResult.message === 'success') {
      setError("");
      setLoading(false);
      return
    }
    if (loginResult.message.includes('auth/wrong-password')) {
      setLoading(false);
      setError(incorrectPwString)
    }else{
      setLoading(false);
      setError(incorrectPwString)
    }
  }, [values, login, incorrectPwString]);

  // change language
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <GuestLayout className="bg-primary relative" >
      <img src="assets/img/login-bg.jpeg" alt="background" className="z-0 absolute w-full h-full top-0 left-0 object-cover" />
      <div className="z-0 absolute w-full h-[50vh] top-[50vh] left-0 bg-slate-800" />
      <img src="assets/img/general-bg.jpg" alt="background" className="z-0 absolute w-full h-[50vh] top-[50vh] left-0 object-cover object-bottom opacity-90" />
      <div className="flex flex-col w-full h-full justify-between items-center overflow-y-auto relative">
        <div
          className="h-[50%] w-full gap-8 flex flex-col"
        >
          {/* welcome */}
          <section className="relative w-full h-20 max-h-20 flex justify-between items-center px-2 md:px-8">
            <img src={logoWhite} alt={logoWhite} className="w-1/2 md:w-auto h-full object-contain" />
            <div
              className="divide-x divide-white text-sm flex py-2 text-white"
            >
              <button
                className={twMerge(
                  `px-1.5 border-solid hover:underline underline-offset-4`,
                  i18n.language === 'en' ? `underline` : ``
                )}
                onClick={() => changeLanguage('en')}
              >EN</button>
              <button
                className={twMerge(
                  `px-1.5 border-solid hover:underline underline-offset-4`,
                  i18n.language === 'zh' ? `underline` : ``
                )}
                onClick={() => changeLanguage('zh')}
              >繁</button>
              <button
                className={twMerge(
                  `px-1.5 border-solid hover:underline underline-offset-4`,
                  i18n.language === 'cn' ? `underline` : ``
                )}
                onClick={() => changeLanguage('cn')}
              >简</button>
            </div>
          </section>

          {/* heading */}
          <section className="h-full flex flex-col justify-center items-center my-2">
            <h1 className="text-2xl md:text-6xl font-bold text-white">{tran(`Welcome`)}</h1>
            <h6 className="text-xl md:text-3xl text-white">{tran(`Transfer Portal`)}</h6>
          </section>
        </div>
        <div className="h-[50%] w-full flex flex-col gap-8 relative">
          {/* form */}
          <section className="w-[20%] min-w-[300px] m-auto -top-6 relative">
            <div className="flex flex-col gap-2">
              <div className="w-full h-[48px] rounded-full bg-white flex justify-start items-center px-4">
                <FaUser size={20} className="text-primary" />
                <input
                  className="w-full bg-transparent p-3 focus:outline-none text-center"
                  type="email"
                  placeholder={emailPlaceholder}
                  value={values.email}
                  onChange={(e) => setValues((v) => ({ ...v, email: e.target.value }))}
                />
              </div>
              <div className="w-full h-[48px] rounded-full bg-white flex justify-start items-center px-4">
                <FaLock size={20} className="text-primary" />
                <input
                  className="w-full bg-transparent p-3 focus:outline-none text-center"
                  type="password"
                  placeholder={pwPlaceHolder}
                  value={values.password}
                  onChange={(e) => setValues((v) => ({ ...v, password: e.target.value }))}
                />
              </div>
              <div className="flex justify-between items-center px-2">
                <div className="flex items-center">
                  <input type="checkbox" className="checked:bg-color-primary indeterminate:bg-color-primary" />
                  <h6 className="mx-1 text-white">{tran(`Remember me`)}</h6>
                </div>
                <button className="text-white"
                  onClick={() => window.location.assign('/forgot-password')}
                >{tran(`Forgot password?`)}</button>
              </div>
              <button className="w-[160px] self-center font-bold bg-white text-primary text-xl py-2 px-6 mt-8 rounded-full" onClick={handleSubmit}>
                {tran(`LOG IN`)}
              </button>
            </div>
          </section>
          {/* footer */}
          <section className="text-center w-full flex flex-col justify-center items-center h-full min-h-[136px]">
            <a className="border-solid border-2 flex items-center text-white py-1 px-4 mb-3 rounded-full"
              href="mailto:info@fulcrum-global.com"
            >
              <IoMail className="mr-2" size={20} />
              <p className="text-lg text-white">{tran(`Contact Us`)}</p>
            </a>
            <div className="text-sm text-white flex gap-2">
              <a className="text-white" href="/privacy-policy" >{tran(`Privacy policy`)}</a>
              <a className="text-white" href="/terms-and-conditions" >{tran(`Terms & Conditions`)}</a>
              {/* <a className="text-white" href="/sitemap.xml" >{tran(`Sitemap`)}</a> */}
            </div>
            <small className="text-white">{`©${dayjs().format('YYYY')} ${tran(`Fulcrum Global (Hong Kong) Limited. All rights reserved.`)}`}</small>
          </section>
        </div>
      </div>
      <IonLoading
        isOpen={loading}
        spinner={`dots`}
      />
      <IonToast
        isOpen={!!error}
        duration={5000}
        onDidDismiss={() => setError("")}
        message={error}
        color='danger'
        buttons={[
          {
            text: 'Dismiss',
            role: 'cancel',
            handler: () => setError('')
          },
        ]}
      />
    </GuestLayout>
  );
}
