import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface Props{
    className?: string;
}
export function CenterContainer({children, className}: PropsWithChildren<Props>){
    return(
        <div className={twMerge(
            `w-full h-full flex flex-col justify-center items-center p-4 gap-4`,
            className ?? ''
        )}>
            {children}
        </div>
    )
}