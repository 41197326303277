import { IonAlert, useIonRouter } from "@ionic/react";
import { useUserContext } from "app/contexts/user-context";
import { AuthLayout } from "components/AuthLayout";
import { SideBar } from "components/SideBar";
import PrivacyView from "components/my-account-views/PrivacyView";
import ResetPasswordView from "components/my-account-views/ResetPasswordView";
import TermsAndConditionsView from "components/my-account-views/TermsAndConditionsView";
import UsefulGuidelineView from "components/my-account-views/UsefulGuidelineView";
import UserMenuView from "components/my-account-views/UserMenuView";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillQuestionCircle, AiOutlineLogout, AiOutlineUserSwitch } from "react-icons/ai";
import { BiChevronRight } from "react-icons/bi";
import { BsTelephoneFill } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { FaUserLarge } from "react-icons/fa6";
import { HiDocumentDownload } from "react-icons/hi";
import { IoMailSharp, IoShieldCheckmarkSharp } from "react-icons/io5";
import { useParams } from "react-router";
import "./AccountPage.css";
import { Content } from "components/Content";

const ICON_SIZE = 18;

type Tabs = "value" | "reset-pw" | "user-menu" | "guideline" | "terms-and-conditions" | "privacy";

const AccountPage: React.FC = () => {
  const [message, setMessage] = useState("");
  const { logout } = useUserContext();
  const { t: tran } = useTranslation();
  const { page } = useParams<{ page: Tabs | undefined }>();
  const pageTarget: Tabs = page ?? 'guideline';
  const [selectedTab, setSelectedTab] = useState<Tabs>(pageTarget);

  useEffect(() => {
    setSelectedTab(page ?? "guideline")
  }, [page])

  return (
    <AuthLayout>
      <SideBar sideMenu>
        <SideMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} onLogout={() => setMessage(tran("Confirm to logout?"))} />
      </SideBar>
      {/* {selectedTab === "value" && <ValueAddedView />} */}
      {selectedTab === "reset-pw" && <ResetPasswordView />}
      {selectedTab === "user-menu" && <UserMenuView />}
      {selectedTab === "guideline" && <UsefulGuidelineView />}
      {selectedTab === "terms-and-conditions" && <Content><TermsAndConditionsView /></Content>}
      {selectedTab === "privacy" && <Content><PrivacyView /></Content>}

      <IonAlert
        isOpen={!!message}
        onDidDismiss={() => setMessage("")}
        message={message}
        buttons={[
          { text: tran("Cancel"), handler: () => setMessage("") },
          {
            text: tran("Confirm"),
            handler: async () => {
              await logout();
              setMessage("");
            },
          },
        ]}
      />
    </AuthLayout>
  );
};

export default AccountPage;

interface SideMenuProps {
  selectedTab: Tabs;
  setSelectedTab: (args0: Tabs) => void;
  onLogout: () => void;
}

export const SideMenu = (props: SideMenuProps) => {
  const { selectedTab, setSelectedTab, onLogout } = props;
  const { user, buyerRecord, multiUserSelected, reselectMultiUsers } = useUserContext();
  const { t: tran } = useTranslation();
  const { push } = useIonRouter();

  const accountBtns: { icon: JSX.Element; name: string; tab: Tabs }[] = [
    {
      icon: <FaLock size={ICON_SIZE} />,
      name: tran("Reset password"),
      tab: "reset-pw",
    },
    {
      icon: <HiDocumentDownload size={ICON_SIZE} />,
      name: tran("Useful Guideline"),
      tab: "guideline",
    },
    {
      icon: <AiFillQuestionCircle size={ICON_SIZE} />,
      name: tran("Terms & Conditions"),
      tab: "terms-and-conditions",
    },
    {
      icon: <IoShieldCheckmarkSharp size={ICON_SIZE} />,
      name: tran("Privacy Policy"),
      tab: "privacy",
    },
  ];

  return (
    <section className="p-4 md:p-4">
      <div className="flex mb-4">
        <div className="rounded-full bg-slate-300 w-[48px] h-[48px] flex justify-center items-center">
          <FaUserLarge size={24} color="white" />
        </div>
        {user && buyerRecord && (
          <div className="flex flex-col items-start justify-center ml-3">
            <h6 className="font-bold text-primary">{tran(`Hi`)}, {user.displayName}</h6>
            <div className="flex justify-start items-center">
              <IoMailSharp size={14} className="text-slate-400" />
              <h6 className="text-sm text-slate-400 ml-2">{user.email}</h6>
            </div>
            <div className="flex justify-start items-center">
              <BsTelephoneFill size={14} className="text-slate-400" />
              <h6 className="text-sm text-slate-400 ml-2">{buyerRecord.fields["Contact Number"]}</h6>
            </div>
          </div>
        )}
      </div>
      <div className="rounded-md border">
        {accountBtns.map((i, idx, arr) => (
          <div
            key={idx}
            className={`px-3 h-10 flex justify-between items-center cursor-pointer transition ${selectedTab === i.tab ? "text-white bg-primary" : "text-primary bg-white"}`}
            onClick={() => {
              setSelectedTab(i.tab);
              push(`/account/${i.tab}`)
            }}
          >
            <div className="flex justify-center items-center mr-2">{i.icon}</div>
            <div className={`text-sm w-full h-full text-left flex justify-between items-center border-b`}>
              <h6 className="ml-2">{i.name}</h6>
              <div className={`transition ${selectedTab === i.tab ? "rotate-180" : "rotate-0"}`}>
                <BiChevronRight size={24} />
              </div>
            </div>
          </div>
        ))}

        {
          multiUserSelected ?
            <div
              className={`px-3 h-10 flex justify-between items-center cursor-pointer transition`}
              onClick={async () => {
                await reselectMultiUsers()
              }}
            >
              <div className="flex justify-center items-center mr-2">
                <AiOutlineUserSwitch size={20} />
              </div>
              <div className={`text-sm w-full h-full text-left flex justify-between items-center border-b`}>
                <h6 className="ml-2">{tran("Switch Users")}</h6>
                <div className={`transition`}>

                  <BiChevronRight size={24} />
                </div>
              </div>
            </div>
            : null
        }

        <div
          className={`px-3 h-10 flex justify-between items-center cursor-pointer transition`}
          onClick={onLogout}
        >
          <div className="flex justify-center items-center mr-2">
            <AiOutlineLogout size={20} />
          </div>
          <div className={`text-sm w-full h-full text-left flex justify-between items-center`}>
            <h6 className="ml-2">{tran("Logout")}</h6>
            <div className={`transition`}>
              <BiChevronRight size={24} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
