import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React from "react";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import ServiceDetails from "pages/AddValueServices/ServiceDetails";
import ValueAddedServicesMain from "pages/AddValueServices/ValueAddedServicesMain";
import { ForgotPasswordPage } from "pages/ForgotPassword";
import LoginPage from "pages/Login";
import PropertyDetails from "pages/MyProperties/PropertyDetails";
import { NotificationPage } from "pages/Notifications/NotificationPage";
import { PublicPrivacyPage } from "pages/PublicPrivacy";
import { PublicTermsPage } from "pages/PublicTermsPage";
import RegisterPage from "pages/Register";
import PublicResetPassword from "pages/ResetPassword";
import UserGateway from "pages/UserGateway";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./App.css";
import AccountPage from "./pages/AccountPage";
import Home from "./pages/HomePage";
import MyPropertiesPage from "./pages/MyPropertiesPage";
import "./theme/variables.css";

import ReactGA from "react-ga4";

// if(process.env.REACT_APP_GA4_MEASUREMENT_ID){
//   console.log("GA4 ID found. Will init GA4");
//   ReactGA.initialize(`${process.env.REACT_APP_GA4_MEASUREMENT_ID}`);
// }else{
//   console.log("GA4 ID Not found. Will NOT init GA4");
// }

// temporary hard coding the GA4 ID
ReactGA.initialize(`G-8CHSJ0YTPL`);

setupIonicReact({ mode: "ios" });

const App: React.FC = () => {
  const {t} = useTranslation();

  // use effect for api viewing
  // useEffect(() => {
  //   const logApi = async () => {
  //     const {data} = await airTableClient.getBaseSchema;
  //     console.log(data);
  //   }
  //   logApi();
  // }, []);

  return (
    <IonApp>
      <Helmet>
        <title>{t(`Landlord Portal | Fulcrum Global`)}</title>
      </Helmet>
      <IonReactRouter>
        {/* <IonTabs> */}
        <IonRouterOutlet>
          {/* static paths */}
          <Route exact path="/" component={Home} />
          <Route exact path="/my-properties" component={MyPropertiesPage} />
          <Route exact path="/account/:page" component={AccountPage} />
          <Route exact path="/services" component={ValueAddedServicesMain} />
          <Route exact path="/notifications" component={NotificationPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/invitation" component={RegisterPage} />
          <Route exact path="/user-gateway" component={UserGateway} />
          <Route exact path="/terms-and-conditions" component={PublicTermsPage} />
          <Route exact path="/privacy-policy" component={PublicPrivacyPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact path="/reset-password" component={PublicResetPassword} />

          {/* Dynamic paths */}
          <Route path={`/services/:id`} component={ServiceDetails} />
          <Route path={`/my-properties/:id`} component={PropertyDetails} />

          {/* fallbacks */}
          <Redirect to="/" />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
